import { formatDateTimeForFile } from "../helpers/dateFormatter";
import * as XLSX from "xlsx";
export function exportExcelFile(blob, fileName, type = "xlsx") {
    let name = fileName;
    const currentDate = new Date().toString();
    const formattedDate = formatDateTimeForFile(currentDate);
    name += "_" + formattedDate;
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = name + "." + type;
    a.click();
}
// function nextChar(c) {
//   return String.fromCharCode(c.charCodeAt(0) + 1);
// }
export const readExcelFile = (blob, callback) => {
    let data;
    const reader = new FileReader();
    reader.onload = (evt) => {
        // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {
            type: "binary",
            // cellStyles: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const formulas = XLSX.utils.sheet_to_formulae(ws);
        // var range = XLSX.utils.decode_range(ws["!ref"]);
        // console.log({ wb, ws });
        const lastCol = Object.keys(ws)[Object.keys(ws).length - 2];
        const lastInd = lastCol.search(/\d/);
        for (var i = "C"; i.length < lastCol.substring(0, lastInd).length ||
            i <= lastCol.substring(0, lastInd); i = nextChar(i)) {
            if (ws[`${i}12`]) {
                ws[`${i}12`] =
                    ws[`${i}12`].t === "e" && ws[`${i}12`].w[0] === "#"
                        ? { t: "s", v: ws[`${i}12`].w, w: ws[`${i}12`].w }
                        : ws[`${i}12`];
            }
        }
        // Hidden rows for budget monitoring
        const monthRegex = new RegExp(Array.from({ length: 12 }, (_, i) => `Month ${i + 1}`).join("|"), "i");
        const firstOccurrence = {};
        const range = XLSX.utils.decode_range(ws["!ref"]);
        let filterRowIndex = -1;
        for (let R = range.s.r; R <= range.e.r; R++) {
            for (let C = range.s.c; C <= range.e.c; C++) {
                const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
                const cell = ws[cellAddress];
                if (cell && cell.v && monthRegex.test(cell.v)) {
                    if (!firstOccurrence[C]) {
                        firstOccurrence[C] = true; // Mark the first occurrence
                    }
                    else {
                        filterRowIndex = R;
                        break;
                    }
                }
            }
            if (filterRowIndex !== -1)
                break;
        }
        data = XLSX.utils.sheet_to_json(ws, {
            header: 1,
            defval: "",
            blankrows: true,
            raw: false,
        });
        if (filterRowIndex !== -1) {
            data = data.filter((_, index) => index < filterRowIndex);
        }
        /* Update state */
        // console.log("Data>>>" + data);
        const c12F = formulas.filter((f) => f.substring(0, 3) === "C12");
        // console.log({ c12F, sub: formulas[51].substring(0, 3) });
        callback(data, c12F[0]);
    };
    reader.readAsBinaryString(blob);
};
function nextChar(c) {
    var u = c.toUpperCase();
    if (u === "Z") {
        var txt = "";
        var i = u.length;
        while (i--) {
            txt += "A";
        }
        return txt + "A";
    }
    else {
        var p = "";
        var q = "";
        if (u.length > 1) {
            p = u.substring(0, u.length - 1);
            q = String.fromCharCode(p.slice(-1).charCodeAt(0));
        }
        var l = u.slice(-1).charCodeAt(0);
        var z = nextLetter(l);
        if (z === "A") {
            return p.slice(0, -1) + nextLetter(q.slice(-1).charCodeAt(0)) + z;
        }
        else {
            return p + z;
        }
    }
}
function nextLetter(l) {
    if (l < 90) {
        return String.fromCharCode(l + 1);
    }
    else {
        return "A";
    }
}
