import ChartApi from "../api/chartApi";
import { TrackedItemsVariables } from "../constants/TrackedItemsConstants";
import isEmpty from "./isEmpty";
import { exportExcelFile, readExcelFile } from "../utils/exportExcelFile";
import { ReportsApi } from "../api/reportsApi";
const { TRACKED_ITEM_VALUE_NUMBER, TRACKED_ITEM_VALUE_PERCENTAGE, TRACKED_ITEM_VALUE_RATIO, } = TrackedItemsVariables;
const valueFormat = {
    [TRACKED_ITEM_VALUE_PERCENTAGE]: `%`,
    [TRACKED_ITEM_VALUE_RATIO]: `:1`,
    [TRACKED_ITEM_VALUE_NUMBER]: ``,
};
export const toFixedDown = (n, fixed) => {
    var _a;
    return (_a = (+`${n}`.match(new RegExp(`^-?\\d+(?:\\.\\d{0,${fixed}})?`))[0])) === null || _a === void 0 ? void 0 : _a.toFixed(fixed);
    // return rounded;
};
// values formating on dashboards as per value type and decimal places configured
export const formatValueByConf = (value, type, decimals = 2, currencyFormat = false) => {
    var _a;
    if (type === TRACKED_ITEM_VALUE_PERCENTAGE) {
        return `${(_a = (value * 100)) === null || _a === void 0 ? void 0 : _a.toFixed(decimals)}${valueFormat[type]}`;
    }
    else if (type === TRACKED_ITEM_VALUE_RATIO) {
        return `${value === null || value === void 0 ? void 0 : value.toFixed(decimals)}${valueFormat[type]}`;
    }
    else {
        return currencyFormat
            ? `${value === null || value === void 0 ? void 0 : value.toFixed(decimals).split(".")[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${decimals ? "." + (value === null || value === void 0 ? void 0 : value.toFixed(decimals).split(".")[1]) : ""}`
            : value === null || value === void 0 ? void 0 : value.toFixed(decimals);
    }
};
export const ChartYAxisLabel = (actualValue, valueType, ChartUnit = 1, decimalPlaces = 2, shouldApplyUnitValue = true //exceptional case for tooltip number type value not apply
) => {
    let resultantValue = shouldApplyUnitValue
        ? actualValue / (ChartUnit !== null && ChartUnit !== void 0 ? ChartUnit : 1)
        : actualValue;
    if (valueType === TRACKED_ITEM_VALUE_PERCENTAGE) {
        resultantValue = resultantValue && resultantValue * 100;
    }
    resultantValue =
        resultantValue && (resultantValue === null || resultantValue === void 0 ? void 0 : resultantValue.toFixed(decimalPlaces !== null && decimalPlaces !== void 0 ? decimalPlaces : 2));
    return `${resultantValue}${valueFormat[valueType]}`;
};
export const exportChartData = (selectedFilterValue, chartData, trackedItemId, selectedRange, chartVariables) => {
    if (!isEmpty(selectedFilterValue)) {
        ChartApi.exportChartData({
            pageNo: 0,
            reportId: trackedItemId,
            chartFilter: selectedFilterValue,
            startDate: selectedRange[0],
            endDate: selectedRange[1],
        })
            .then((blob) => {
            exportExcelFile(blob, chartVariables.name);
        })
            .catch((error) => console.log("error: ", error));
    }
};
export const exportActualDataForChart = (selectedFilterValue, trackedItemId, selectedRange, chartVariables, listAssociations) => {
    if (!isEmpty(selectedFilterValue)) {
        ChartApi.exportActualDataForChart({
            chartDataRequestDTO: {
                pageNo: 0,
                reportId: trackedItemId,
                chartFilter: selectedFilterValue,
                startDate: selectedRange[0],
                endDate: selectedRange[1],
            },
            listAssociations: listAssociations,
        })
            .then((blob) => {
            exportExcelFile(blob, chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.name);
        })
            .catch((error) => console.log("error: ", error));
    }
};
export const exportFinancialDataSheet = (selectedFilterValue, trackedItemId, selectedRange, chartVariables, callback = null, isDisplay = false) => {
    if (!isEmpty(selectedFilterValue)) {
        ChartApi.exportFinancialDataForChart({
            pageNo: 0,
            reportId: trackedItemId,
            chartFilter: selectedFilterValue,
            startDate: selectedRange[0],
            endDate: selectedRange[1],
        })
            .then((blob) => isDisplay
            ? readExcelFile(blob, callback)
            : exportExcelFile(blob, chartVariables.name))
            .catch((error) => console.log("error: ", error));
    }
};
export const exportBudgetMonitoringReportsSheet = (payload, companyId, reportId, tabName) => {
    if (!isEmpty(companyId)) {
        ReportsApi.getBudgetReportExcelSheet(Object.assign(Object.assign({}, payload), { companyId,
            reportId }))
            .then((blob) => exportExcelFile(blob, tabName))
            .catch((error) => console.log("error: ", error));
    }
};
export const chartDataMinMaxPercentage = (data, percentageValue) => {
    var _a, _b, _c, _d, _e;
    if (Array.isArray(data === null || data === void 0 ? void 0 : data.colorCodeList)) {
        const minMaxValue = {
            min: (_b = (_a = data === null || data === void 0 ? void 0 : data.colorCodeList[0]) === null || _a === void 0 ? void 0 : _a.startPoint) !== null && _b !== void 0 ? _b : 0,
            max: (_e = (_d = data === null || data === void 0 ? void 0 : data.colorCodeList[((_c = data === null || data === void 0 ? void 0 : data.colorCodeList) === null || _c === void 0 ? void 0 : _c.length) - 1]) === null || _d === void 0 ? void 0 : _d.endPoint) !== null && _e !== void 0 ? _e : 1,
        };
        // console.log({ minMaxValue });
        return (minMaxValue.max - minMaxValue.min) * (percentageValue / 100);
    }
    return 0;
};
