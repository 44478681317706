import React, { useState } from "react";
import { getBreadCrumbSession, getButtonTextKey, } from "../../../../../core/helpers/localStorage/breadCrumbSession";
import { SessionStorage } from "../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../core/constants/SessionVariables";
import { WatchlistVariables } from "../../../../../core/constants/watchlistConstants";
import { Button } from "antd";
import { dashboardRoute, watchlistRoute, } from "../../../../../core/constants/routesPath";
import { useHistory } from "react-router-dom";
const BackToOverviewButton = (isTIDashboard = false) => {
    const history = useHistory();
    const [buttonText, setButtonText] = useState(getButtonTextKey);
    const updateButtonText = () => {
        var _a;
        const ids = (_a = getBreadCrumbSession()) === null || _a === void 0 ? void 0 : _a.map((item) => parseInt(item.key));
        const level = SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
        if (buttonText === WatchlistVariables.WATCHLIST_BREADCRUMBS) {
            SessionStorage.setKey(SessionVariables.BUTTON_TEXT, WatchlistVariables.WATCHLIST, sessionStorage);
            return;
        }
        if (getButtonTextKey() === WatchlistVariables.WATCHLIST &&
            ids[(ids === null || ids === void 0 ? void 0 : ids.length) - 1] !==
                SessionStorage.getKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage) &&
            level === null) {
            SessionStorage.setKey(SessionVariables.BUTTON_TEXT, WatchlistVariables.OVERVIEW, sessionStorage);
            SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_ID, sessionStorage);
            setButtonText(WatchlistVariables.OVERVIEW);
            SessionStorage.setKey(SessionVariables.EXPANDED_KEYS, (ids === null || ids === void 0 ? void 0 : ids.length) > 0 ? ids : undefined, sessionStorage);
        }
        // if (level !== "Company" && level !== "Group") {
        SessionStorage.removeKey(SessionVariables.WATCHLIST_ITEM_LEVEL, sessionStorage);
        // }
    };
    return (React.createElement(React.Fragment, null, buttonText && (React.createElement(Button, { style: !isTIDashboard
            ? { right: "0px", marginTop: "-2px", position: "absolute" }
            : {}, type: "primary", onClick: () => {
            // clearDashboardSession();
            if (buttonText === WatchlistVariables.OVERVIEW) {
                history.push(dashboardRoute);
            }
            else if (buttonText === WatchlistVariables.WATCHLIST ||
                buttonText === WatchlistVariables.WATCHLIST_BREADCRUMBS) {
                history.push(watchlistRoute);
            }
        } },
        (buttonText === WatchlistVariables.WATCHLIST ||
            buttonText === WatchlistVariables.WATCHLIST_BREADCRUMBS) &&
            updateButtonText(),
        buttonText === WatchlistVariables.OVERVIEW
            ? "Back To Overview"
            : "Back To Watchlist"))));
};
export default BackToOverviewButton;
