import React from "react";
import { ReportsListingColumns } from "./reportsListingColumns";
import CustomTable from "../../../../../layout/components/customTable";
import { budgetReportsScrollPosition, reportsScrollPosition, } from "../../../../../core/constants/tableScrollConstants";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ReportFilterOptions } from "../../../../../core/enums/report";
import { ReportsVariables } from "../../../../../core/constants/ReportConstants";
const FinancialReportsTable = ({ data, responseColumns, timePeriod = ReportFilterOptions.Default, hasChildrenExpanded = false, actions, reportType = null, reportId = null, isPrintMode = null, titleKey = null, parentKey = null, getDataSheet = null, }) => {
    const columns = ReportsListingColumns(responseColumns, hasChildrenExpanded, actions, reportType, reportId, isPrintMode, titleKey, timePeriod, getDataSheet);
    return (React.createElement("div", { "data-test": "financial-reports-table", className: `${isPrintMode ? "loadDataClass" : ""} reportPrintMode fixedColumnListing ${(data === null || data === void 0 ? void 0 : data.length) && `rowStyle`}` },
        React.createElement(CustomTable, { key: isPrintMode, data: data, dataType: "any", rowClassName: (record, index, expandedRowKeys) => {
                let rowClass = (record === null || record === void 0 ? void 0 : record.category) ? "boldRow" : "nestedRow";
                if (expandedRowKeys.includes(record === null || record === void 0 ? void 0 : record.month0)) {
                    rowClass += ` expandedRow`;
                }
                if ((hasChildrenExpanded || parentKey) &&
                    reportType &&
                    (record === null || record === void 0 ? void 0 : record.type) === reportType) {
                    rowClass += ` selectedRow`;
                }
                return rowClass;
            }, expandIconHandler: ({ expanded, onExpand, record }) => (record === null || record === void 0 ? void 0 : record.category) &&
                (expanded ? (React.createElement(UpOutlined, { className: "expandedCollapseIcon", onClick: (e) => onExpand(record, e) })) : (React.createElement(DownOutlined, { className: "expandedCollapseIcon", onClick: (e) => onExpand(record, e) }))), columns: columns, scrollPosition: reportId > ReportsVariables.BUDGET_VS_ACTUAL_ID
                ? budgetReportsScrollPosition
                : reportsScrollPosition, scroll: isPrintMode ? false : true, hasChildrenExpanded: hasChildrenExpanded, defaultExpandKeys: parentKey })));
};
export default React.memo(FinancialReportsTable);
