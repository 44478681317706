var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useEffect, useState } from "react";
import { Button, Checkbox, Col, DatePicker, Input, Row, Select, Tabs, } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { isUniqueExternalReference, isUniqueSiteCQCNumber, } from "../../../../../core/validations/companiesValidation";
import { cqcRegistrationNumberRegex, numberRegex, mobileNumberRegex, onlyAlpabeticCharactersRegex, telephoneNumberRegex, ukPostCodeRegex, } from "../../../../../core/utils/regex";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import moment from "moment";
import { sitesRoute } from "../../../../../core/constants/routesPath";
import { SiteApi } from "../../../../../core/api/siteApi";
import RegisterAddress from "../../../../../layout/components/address/RegisterAddress";
import ContactAddress from "../../../../../layout/components/address/ContactAddress";
import LookupDropDown from "../../../../../layout/components/select/LookupDropDown";
import localize from "../../../../../core/utils/translation-files";
import { inputDefaultLength, telephoneNumberDefaultLength, } from "../../../../../core/utils/default-length";
import { LookupNames } from "../../../../../core/constants/lookup";
import UsePrintPage from "../../../../../core/hooks/usePrintPage";
import ConditionalViewFormField from "../../../../../layout/components/conditionalViewFormField";
import telephoneTypeByValue from "../../../../../core/utils/telephoneTypeByValue";
import PromptComponent from "../../../../../layout/components/prompt-message";
import isEmpty from "../../../../../core/helpers/isEmpty";
import FormActionButtons from "../../../../../layout/components/common/FormsActionButtons";
import Asterik from "../../../shared/helper-components/Asterik";
import { isCurrentFieldGreaterOrEquall, isPositiveValue, } from "../../../../../core/validations/valuesComparison";
import { useSelector } from "react-redux";
import UseToggleStateOnScroll from "../../../../../core/hooks/useToggleStateOnScroll";
import SiteDetailsFormFields from "../../site-details/siteDetailsFormFields";
import XeroFormFields from "../../site-details/xeroFormFields";
const Option = Select.Option;
const { TabPane } = Tabs;
const defaultSubmitText = "Add Site";
const optionFilter = (input, option) => option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0;
const contactTypes = [
    {
        value: TelephoneType.Mobile,
        name: "Mobile",
    },
    {
        value: TelephoneType.Landline,
        name: "Landline",
    },
];
export const SiteForm = ({ submitText = defaultSubmitText, site, companies, onSubmit, isEdit = false, isReadOnly = false, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
    const [isUniqueER, setIsUniqueER] = useState(true);
    const { handleSubmit, handleChange, handleBlur, setFieldValue, values, errors, touched, resetForm, dirty, setErrors, setValues, } = useFormik({
        validationSchema: Yup.object({
            name: Yup.string()
                .required(localize(["Site.siteName", "isRequired!"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            cqcRegistrationNumber: Yup.string()
                .required(localize([
                "Company.CQC",
                "Company.registration",
                "number",
                "isRequired!",
            ]))
                .matches(cqcRegistrationNumberRegex.regex, cqcRegistrationNumberRegex.message)
                .max(11, localize(["Company.mustHaveFormat"]))
                .test("check", localize([
                "Company.CQC",
                "Company.registration",
                "number",
                "alreadyExist!",
            ]), function (value) {
                return isUniqueSiteCQCNumber(value, companies, site);
            }),
            contactName: Yup.string()
                .required(localize(["Site.managerName", "isRequired!"]))
                .matches(onlyAlpabeticCharactersRegex.regex, onlyAlpabeticCharactersRegex.message)
                .max(50, localize(["Company.lengthMustNotBeGreaterThan50"])),
            primaryTelephoneType: Yup.number().required(localize(["Company.telephoneType", "isRequired!"])),
            primaryTelephoneNumber: Yup.string().when("primaryTelephoneType", {
                is: TelephoneType.Landline,
                then: Yup.string()
                    .required(localize(["primary", "contact", "number", "isRequired!"]))
                    .matches(telephoneNumberRegex.regex, telephoneNumberRegex.message)
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
                otherwise: Yup.string()
                    .required(localize(["primary", "contact", "number", "isRequired!"]))
                    .matches(mobileNumberRegex.regex, mobileNumberRegex.message)
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
            }),
            secondaryTelephoneNumber: Yup.string()
                .when("secondaryTelephoneType", {
                is: TelephoneType.Landline,
                then: Yup.string()
                    .matches(telephoneNumberRegex.regex, localize([telephoneNumberRegex.message]))
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
                otherwise: Yup.string()
                    .matches(mobileNumberRegex.regex, localize([mobileNumberRegex.message]))
                    .max(telephoneNumberDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${telephoneNumberDefaultLength}`,
                    "characters",
                ])),
            })
                .nullable(),
            emailAddress: Yup.string()
                .required(localize(["email", "address", "isRequired!"]))
                .email(localize(["enterAValid", "email"]))
                .max(inputDefaultLength, localize([
                "mustBe",
                "lessThan",
                `${inputDefaultLength}`,
                "characters",
            ])),
            externalReference: Yup.string()
                .required(localize(["Site.apiSiteId", "isRequired!"]))
                .matches(numberRegex.regex, numberRegex.message)
                .test("check", localize(["TrackedItem.apiId", "alreadyExist!"]), function (value) {
                return isUniqueER;
            })
                .nullable(),
            siteDetail: Yup.object().shape({
                website: Yup.string(),
                financialMonth: Yup.string()
                    .required(localize(["Site.financialYearEnd", "isRequired!"]))
                    .nullable()
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ]))
                    .nullable(),
                noOfRegisteredBeds: Yup.string()
                    .trim()
                    .label(localize(["Site.numOfRegisteredBeds"]))
                    .required(localize(["Site.numOfRegisteredBeds", "isRequired!"]))
                    .matches(/^[0-9]*$/, localize(["mustBeaPositiveInteger"]))
                    .max(9, localize(["mustBe", "lessThan", "10", "digits"]))
                    .test("noOfRegisteredBeds", localize(["mustBe", "greaterThan", "orEqualTo", "1"]), function (value) {
                    return isCurrentFieldGreaterOrEquall(value, 1);
                })
                    .nullable(),
                financialSystem: Yup.string()
                    .required(localize(["Site.financialSystem", "isRequired!"]))
                    .nullable(),
                ingestionYear: Yup.string()
                    .required(localize(["Site.ingestionStartYear", "isRequired!"]))
                    .nullable(),
                tenantId: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.tenantId", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                accessToken: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.accessToken", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                refreshToken: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.refreshToken", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                clientId: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.clientId", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                clientSecret: Yup.string()
                    .when("financialSystem", {
                    is: "1",
                    then: Yup.string()
                        .required(localize(["Site.clientSecret", "isRequired!"]))
                        .nullable(),
                })
                    .nullable(),
                safe: Yup.string().max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                effective: Yup.string().max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                caring: Yup.string().max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                responsive: Yup.string().max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                wellLed: Yup.string().max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
            }),
            addresses: Yup.array().of(Yup.object().shape({
                name: Yup.string()
                    .matches(/^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"]))
                    .max(inputDefaultLength, localize([
                    "mustBe",
                    "lessThan",
                    `${inputDefaultLength}`,
                    "characters",
                ])),
                line1: Yup.string()
                    .required(localize(["Company.line1", "isRequired!"]))
                    .matches(/^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"]))
                    .max(75, localize(["mustBe", "lessThan", "75", "characters"])),
                line2: Yup.string()
                    .max(75, localize(["mustBe", "lessThan", "75", "characters"]))
                    .matches(/^[a-z\d\-_\s]+$/i, localize(["special", "characters", "notAllowed!"])),
                town: Yup.string()
                    .required(localize(["Company.town", "isRequired!"]))
                    .max(25, localize(["mustBe", "lessThan", "25", "characters"]))
                    .matches(onlyAlpabeticCharactersRegex.regex, onlyAlpabeticCharactersRegex.message),
                county: Yup.string()
                    .max(25, localize(["mustBe", "lessThan", "25", "characters"]))
                    .matches(onlyAlpabeticCharactersRegex.regex, onlyAlpabeticCharactersRegex.message),
                postCode: Yup.string()
                    .required(localize(["Company.postCode", "isRequired!"]))
                    .matches(ukPostCodeRegex.regex, ukPostCodeRegex.message)
                    .min(6, localize(["mustBe", "greaterThan", "6", "characters"]))
                    .max(8, localize(["mustBe", "lessThan", "8", "characters"])),
            })),
        }),
        initialValues: site,
        onSubmit: (values) => {
            if (!cqcAlreadyExist) {
                onSubmit(values, resetForm, setErrors);
            }
            // resetForm();
        },
    });
    const history = useHistory();
    const [key, setKey] = useState(0);
    const [showCQCRatingFields, setShowCQCRatingFields] = useState(true);
    const [cqcAlreadyExist, setCqcAlreayExist] = useState(false);
    const [selectedTab, setSelectedTab] = useState("1");
    const { visible: primaryVisible, setVisible: setPrimaryVisible } = UseToggleStateOnScroll();
    const { visible: secondaryVisible, setVisible: setSecondaryVisible } = UseToggleStateOnScroll();
    const checkUniqueER = () => {
        if (values.externalReference)
            setIsUniqueER(isUniqueExternalReference(values === null || values === void 0 ? void 0 : values.externalReference, site === null || site === void 0 ? void 0 : site.externalReference));
    };
    useEffect(() => {
        checkCQCRating(site.cqcRegistrationNumber);
        return () => {
            setKey(0);
            setShowCQCRatingFields(true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function disabledDate(current) {
        return current && current > moment(); //.add(-1, "days");
    }
    const hide = useSelector((state) => state.togglePrint);
    const onSameAddressChange = (e) => {
        var _a, _b, _c, _d, _e, _f;
        if (e.target.checked) {
            setFieldValue(`addresses.${0}.type`, 1);
            setFieldValue(`addresses.${1}.name`, (_a = values.addresses[0]) === null || _a === void 0 ? void 0 : _a.name);
            setFieldValue(`addresses.${1}.line1`, (_b = values.addresses[0]) === null || _b === void 0 ? void 0 : _b.line1);
            setFieldValue(`addresses.${1}.line2`, (_c = values.addresses[0]) === null || _c === void 0 ? void 0 : _c.line2);
            setFieldValue(`addresses.${1}.town`, (_d = values.addresses[0]) === null || _d === void 0 ? void 0 : _d.town);
            setFieldValue(`addresses.${1}.county`, (_e = values.addresses[0]) === null || _e === void 0 ? void 0 : _e.county);
            setFieldValue(`addresses.${1}.postCode`, (_f = values.addresses[0]) === null || _f === void 0 ? void 0 : _f.postCode);
        }
        else {
            setFieldValue(`addresses.${0}.type`, 2);
            setFieldValue(`addresses.${1}.name`, "");
            setFieldValue(`addresses.${1}.line1`, "");
            setFieldValue(`addresses.${1}.line2`, "");
            setFieldValue(`addresses.${1}.town`, "");
            setFieldValue(`addresses.${1}.county`, "");
            setFieldValue(`addresses.${1}.postCode`, "");
            setFieldValue(`addresses.${1}.type`, 3);
        }
    };
    const handleTelephoneChange = (value) => {
        setFieldValue("primaryTelephoneType", value);
        setFieldValue("primaryTelephoneNumber", "");
    };
    const handleSecondaryTelephoneChange = (value) => {
        setFieldValue("secondaryTelephoneType", value);
        setFieldValue("secondaryTelephoneNumber", "");
    };
    const checkCQCRating = (cqc) => __awaiter(void 0, void 0, void 0, function* () {
        if (cqc && cqc.length > 8) {
            yield SiteApi.getCQCWidget(cqc).then((response) => {
                setShowCQCRatingFields(response);
            });
        }
    });
    const handleCQCChange = (e) => {
        if (e.target.value && e.target.value.length > 9) {
            checkCQCRating(e.target.value);
            SiteApi.checkCQCExist((site === null || site === void 0 ? void 0 : site.id) ? site.id : null, e.target.value).then((res) => {
                setCqcAlreayExist(res.cqcExists);
            });
        }
    };
    const handleChangeRegisterActivities = useCallback((inputName, value) => {
        setFieldValue(inputName, value.join("@"));
    }, [setFieldValue]);
    const { handlePrint, nodeRef } = UsePrintPage(true);
    const handleFieldChange = (event) => {
        var _a;
        const { name, value } = (_a = event.target) !== null && _a !== void 0 ? _a : {};
        let updatedValue = value;
        if (isEmpty(value)) {
            updatedValue = null;
        }
        else if (isPositiveValue(value)) {
            updatedValue =
                (value === null || value === void 0 ? void 0 : value.length) === String(Number(value)).length ? Number(value) : value;
        }
        if (name.includes(".")) {
            setFieldValue(name, updatedValue);
            return;
        }
        setValues((prev) => (Object.assign(Object.assign({}, prev), { [name]: updatedValue })));
    };
    // const months = useSelector(
    //   (state: RootStateOrAny) => state.lookups[LookupNames.LKMonth] ?? []
    // );
    const registedActivites = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKRegisteredActivities]) !== null && _a !== void 0 ? _a : []; });
    return (React.createElement("div", { ref: nodeRef, "data-test": "siteFormComponent" },
        React.createElement(PromptComponent, { isPrompt: dirty }),
        React.createElement("form", { className: `mb-1 customForm ${isReadOnly && `viewForm`}`, name: "basic", onSubmit: handleSubmit, key: key, id: "my-form" },
            React.createElement(Input, { name: "id", type: "hidden" }),
            React.createElement(Input, { name: "type", type: "hidden", value: "1" }),
            React.createElement(Input, { name: "parentId", type: "hidden", value: "1" }),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["Company.basic", "information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["Site.site", "name"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.name },
                                        React.createElement(Input, { type: "text", name: "name", placeholder: localize(["Site.site", "name"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.name, className: `${errors.name && touched.name ? "input-error" : null}` }),
                                        errors.name && touched.name && (React.createElement("p", { className: "error" }, errors.name))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", { "data-test": "website" }, localize(["Company.website"]))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.website },
                                        React.createElement(Input, { name: `siteDetail.website`, placeholder: localize(["Company.website"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.siteDetail.website, className: `${errors &&
                                                errors.siteDetail &&
                                                errors.siteDetail.website &&
                                                touched &&
                                                touched.siteDetail &&
                                                touched.siteDetail.website
                                                ? "input-error"
                                                : null}` }),
                                        errors &&
                                            errors.siteDetail &&
                                            errors.siteDetail.website &&
                                            touched &&
                                            touched.siteDetail &&
                                            touched.siteDetail.website ? (React.createElement("p", { className: "error" }, errors &&
                                            errors.siteDetail &&
                                            errors.siteDetail.website)) : values &&
                                            values.siteDetail &&
                                            values.siteDetail.website !== "" ? (React.createElement(Button, { "data-test": "gotoSiteBtn", style: { width: "100%" }, type: "dashed", block: true, onClick: () => window.open(values.siteDetail.website.includes("https://")
                                                ? values.siteDetail.website
                                                : "https://" + values.siteDetail.website, "_blank") }, localize(["Button.goToWebsite"]))) : null))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize([
                                                "Company.CQC",
                                                "Company.registration",
                                                "number",
                                            ]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.cqcRegistrationNumber },
                                        React.createElement(Input, { name: `cqcRegistrationNumber`, placeholder: "N-NNNNNNNNN (e.g. 1-234567890)", onBlur: handleBlur, onChange: (e) => {
                                                handleChange(e);
                                                handleCQCChange(e);
                                            }, defaultValue: values.cqcRegistrationNumber, className: `${(errors &&
                                                errors.cqcRegistrationNumber &&
                                                touched &&
                                                touched.cqcRegistrationNumber) ||
                                                cqcAlreadyExist
                                                ? "input-error"
                                                : null}` }),
                                        errors &&
                                            errors.cqcRegistrationNumber &&
                                            touched &&
                                            touched.cqcRegistrationNumber ? (React.createElement("p", { className: "error" }, errors && errors.cqcRegistrationNumber)) : cqcAlreadyExist ? (React.createElement("p", { className: "error" }, localize([
                                            "Company.CQC",
                                            "Company.registration",
                                            "number",
                                            "alreadyExist!",
                                        ]))) : null))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["Site.apiSiteId"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.externalReference },
                                        React.createElement(Input, { name: "externalReference", placeholder: localize(["Site.apiSiteId"]), onChange: handleFieldChange, defaultValue: values.externalReference, className: `${errors &&
                                                errors.externalReference &&
                                                touched &&
                                                touched.externalReference
                                                ? "input-error"
                                                : null}` }),
                                        errors &&
                                            errors.externalReference &&
                                            touched &&
                                            touched.externalReference && (React.createElement("p", { className: "error" }, errors && errors.externalReference)))))),
                        React.createElement(SiteDetailsFormFields, { values: values, errors: errors, touched: touched, handleChange: handleChange, setFieldValue: setFieldValue, isReadOnly: isReadOnly })))),
            values.siteDetail.financialSystem === 1 && (React.createElement(XeroFormFields, { values: values, errors: errors, touched: touched, handleChange: handleChange, isReadOnly: isReadOnly, setFieldValue: setFieldValue })),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["CQC Rating Information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement(Row, { justify: "space-between" },
                            React.createElement(Col, { span: 24 },
                                React.createElement("div", { className: "row" },
                                    showCQCRatingFields && (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                            React.createElement("div", { className: "form-group" },
                                                React.createElement("div", { className: "ant-col bold-text" },
                                                    React.createElement("label", null, localize(["Site.cQCRatingDate"]))),
                                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: moment(values.siteDetail.cqcRatingDate
                                                        ? values.siteDetail.cqcRatingDate
                                                        : new Date()).format("YYYY-MM-DD") },
                                                    React.createElement(DatePicker, { value: moment(values.siteDetail.cqcRatingDate
                                                            ? values.siteDetail.cqcRatingDate
                                                            : new Date()), format: "YYYY-MM-DD", className: `mb-2 mb-md-0 d-block ant-input  ${((_a = errors === null || errors === void 0 ? void 0 : errors.siteDetail) === null || _a === void 0 ? void 0 : _a.cqcRatingDate) &&
                                                            ((_b = touched === null || touched === void 0 ? void 0 : touched.siteDetail) === null || _b === void 0 ? void 0 : _b.cqcRatingDate)
                                                            ? `input-error`
                                                            : ``}`, disabledDate: disabledDate, allowClear: false, placeholder: localize([
                                                            "UserRole.startDateAndTime",
                                                        ]), onChange: (date, dateString) => {
                                                            setFieldValue(`siteDetail.cqcRatingDate`, dateString ? dateString : "");
                                                        } }),
                                                    errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.cqcRatingDate &&
                                                        touched &&
                                                        touched.siteDetail &&
                                                        touched.siteDetail.cqcRatingDate && (React.createElement("p", { className: "error" }, errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.cqcRatingDate))))),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                            React.createElement("div", { className: "form-group" },
                                                React.createElement("div", { className: "ant-col bold-text" },
                                                    React.createElement("label", null, localize(["Site.safe"]))),
                                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.safe },
                                                    React.createElement(Input, { name: "siteDetail.safe", placeholder: localize(["Site.safe"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.siteDetail.safe, className: `${errors &&
                                                            errors.siteDetail &&
                                                            errors.siteDetail.safe &&
                                                            touched &&
                                                            touched.siteDetail &&
                                                            touched.siteDetail.safe
                                                            ? "input-error"
                                                            : null}` }),
                                                    errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.safe &&
                                                        touched &&
                                                        touched.siteDetail &&
                                                        touched.siteDetail.safe && (React.createElement("p", { className: "error" }, errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.safe))))))),
                                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                        React.createElement("div", { className: "form-group" },
                                            React.createElement("div", { className: "ant-col bold-text" },
                                                React.createElement("label", { "data-test": "registerationActivities" }, localize(["Site.registeredActivities"]))),
                                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, 
                                                // labelText={
                                                //   values.siteDetail.registeredActivities.length > 0
                                                //     ? values.siteDetail.registeredActivities.split(
                                                //         "@"
                                                //       )
                                                //     : ""
                                                // }
                                                labelText: values.siteDetail.registeredActivities.length > 0
                                                    ? registedActivites.map((item) => {
                                                        if ((item === null || item === void 0 ? void 0 : item.code) ===
                                                            values.siteDetail.registeredActivities.split("@")[0]) {
                                                            return item === null || item === void 0 ? void 0 : item.text;
                                                        }
                                                        else
                                                            return null;
                                                    })
                                                    : "" },
                                                React.createElement(LookupDropDown, { className: "mb-lg-0 mb-4 registered-activities-dropdown", allowClear: true, valueType: "string", mode: "multiple", inputName: "siteDetail.registeredActivities", localizationKey: ["Site.registeredActivities"], lookUpType: LookupNames.LKRegisteredActivities, selectedValue: values.siteDetail.registeredActivities.length > 0
                                                        ? values.siteDetail.registeredActivities.split("@")
                                                        : "", onChange: handleChangeRegisterActivities })))),
                                    !showCQCRatingFields ? (React.createElement(Col, { span: 24 },
                                        React.createElement("iframe", { id: "cqc-rating-iframe", title: "CQC Widget", src: `https://www.cqc.org.uk/widget/${values.cqcRegistrationNumber}`, width: "100%", height: "270", "data-isloaded": "0", frameBorder: "0", scrolling: "no" },
                                            React.createElement("p", null,
                                                React.createElement("a", { href: `https://www.cqc.org.uk/directory/${values.cqcRegistrationNumber}?referer=widget1` }, localize(["Site.careQualityCommission"])))))) : (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                            React.createElement("div", { className: "form-group" },
                                                React.createElement("div", { className: "ant-col bold-text" },
                                                    React.createElement("label", null, localize(["Site.effective"]))),
                                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.effective },
                                                    React.createElement(Input, { name: "siteDetail.effective", placeholder: localize(["Site.effective"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.siteDetail.effective, className: `${errors &&
                                                            errors.siteDetail &&
                                                            errors.siteDetail.effective &&
                                                            touched &&
                                                            touched.siteDetail &&
                                                            touched.siteDetail.effective
                                                            ? "input-error"
                                                            : null}` }),
                                                    errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.effective &&
                                                        touched &&
                                                        touched.siteDetail &&
                                                        touched.siteDetail.effective && (React.createElement("p", { className: "error" }, errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.effective))))),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                            React.createElement("div", { className: "form-group" },
                                                React.createElement("div", { className: "ant-col bold-text" },
                                                    React.createElement("label", null, localize(["Site.caring"]))),
                                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.caring },
                                                    React.createElement(Input, { name: "siteDetail.caring", placeholder: localize(["Site.caring"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.siteDetail.caring, className: `${errors &&
                                                            errors.siteDetail &&
                                                            errors.siteDetail.caring &&
                                                            touched &&
                                                            touched.siteDetail &&
                                                            touched.siteDetail.caring
                                                            ? "input-error"
                                                            : null}` }),
                                                    errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.caring &&
                                                        touched &&
                                                        touched.siteDetail &&
                                                        touched.siteDetail.caring && (React.createElement("p", { className: "error" }, errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.caring))))),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                            React.createElement("div", { className: "form-group" },
                                                React.createElement("div", { className: "ant-col bold-text" },
                                                    React.createElement("label", null, localize(["Site.responsive"]))),
                                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.responsive },
                                                    React.createElement(Input, { name: "siteDetail.responsive", placeholder: localize(["Site.responsive"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.siteDetail.responsive, className: `${errors &&
                                                            errors.siteDetail &&
                                                            errors.siteDetail.responsive &&
                                                            touched &&
                                                            touched.siteDetail &&
                                                            touched.siteDetail.responsive
                                                            ? "input-error"
                                                            : null}` }),
                                                    errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.responsive &&
                                                        touched &&
                                                        touched.siteDetail &&
                                                        touched.siteDetail.responsive && (React.createElement("p", { className: "error" }, errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.responsive))))),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" }),
                                        React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                            React.createElement("div", { className: "form-group" },
                                                React.createElement("div", { className: "ant-col bold-text" },
                                                    React.createElement("label", null, localize(["Site.wellLed"]))),
                                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.wellLed },
                                                    React.createElement(Input, { name: "siteDetail.wellLed", placeholder: localize(["Site.wellLed"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.siteDetail.wellLed, className: `${errors &&
                                                            errors.siteDetail &&
                                                            errors.siteDetail.wellLed &&
                                                            touched &&
                                                            touched.siteDetail &&
                                                            touched.siteDetail.wellLed
                                                            ? "input-error"
                                                            : null}` }),
                                                    errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.wellLed &&
                                                        touched &&
                                                        touched.siteDetail &&
                                                        touched.siteDetail.wellLed && (React.createElement("p", { className: "error" }, errors &&
                                                        errors.siteDetail &&
                                                        errors.siteDetail.wellLed))))))))))))),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["contact", "information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement("div", { className: "row" },
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["contact", "Company.person", "name"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.contactName },
                                        React.createElement(Input, { name: "contactName", placeholder: localize([
                                                "contact",
                                                "Company.person",
                                                "name",
                                            ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.contactName, className: `${errors.contactName && touched.contactName
                                                ? "input-error"
                                                : null}` }),
                                        errors.contactName && touched.contactName && (React.createElement("p", { className: "error" }, errors.contactName))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", null,
                                            localize(["email", "Address"]),
                                            " ",
                                            React.createElement(Asterik, { hidden: isReadOnly }))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.emailAddress },
                                        React.createElement(Input, { name: "emailAddress", placeholder: localize(["email", "Address"]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.emailAddress, className: `${errors.emailAddress && touched.emailAddress
                                                ? "input-error"
                                                : null}` }),
                                        errors.emailAddress && touched.emailAddress && (React.createElement("p", { className: "error" }, errors.emailAddress))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", { "data-test": "primaryContactType" }, localize(["primary", "contact", "type"]))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: telephoneTypeByValue(values.primaryTelephoneType, contactTypes) },
                                        React.createElement(Select, { showSearch: true, className: "mb-lg-0 mb-4", defaultValue: values.primaryTelephoneType, placeholder: localize([
                                                "select",
                                                "primary",
                                                "contact",
                                                "type",
                                            ]), optionFilterProp: "children", filterOption: optionFilter, onChange: handleTelephoneChange, open: primaryVisible, onDropdownVisibleChange: setPrimaryVisible }, contactTypes.map((option) => (React.createElement(Option, { key: option.value + "primary", value: option.value, id: "option-div-container" }, option.name)))),
                                        errors.primaryTelephoneType &&
                                            touched.primaryTelephoneType && (React.createElement("p", { className: "error" }, errors.primaryTelephoneType))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", key: values.primaryTelephoneType + "primary" }, values.primaryTelephoneType === TelephoneType.Mobile ? (React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "primaryMobileNumber" },
                                        localize(["primary", "mobile", "number"]),
                                        " ",
                                        React.createElement(Asterik, { hidden: isReadOnly }))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.primaryTelephoneNumber },
                                    React.createElement(Input, { name: "primaryTelephoneNumber", placeholder: localize([
                                            "primary",
                                            "mobile",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: (isEdit ? "" : "07") + values.primaryTelephoneNumber, className: `${errors.primaryTelephoneNumber &&
                                            touched.primaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.primaryTelephoneNumber &&
                                        touched.primaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.primaryTelephoneNumber))))) : (React.createElement("div", { className: "form-group", key: values.primaryTelephoneType + "primary" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "primaryTelephoneNumber" },
                                        localize(["primary", "telephone", "number"]),
                                        " ",
                                        React.createElement(Asterik, { hidden: isReadOnly }))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.primaryTelephoneNumber },
                                    React.createElement(Input, { name: "primaryTelephoneNumber", placeholder: localize([
                                            "primary",
                                            "telephone",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.primaryTelephoneNumber, className: `${errors.primaryTelephoneNumber &&
                                            touched.primaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.primaryTelephoneNumber &&
                                        touched.primaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.primaryTelephoneNumber)))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                                React.createElement("div", { className: "form-group" },
                                    React.createElement("div", { className: "ant-col bold-text" },
                                        React.createElement("label", { "data-test": "secondaryContactType" }, localize(["secondary", "telephone", "number"]))),
                                    React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: telephoneTypeByValue(values.secondaryTelephoneType, contactTypes) },
                                        React.createElement(Select, { showSearch: true, className: "mb-lg-0 mb-4", defaultValue: values.secondaryTelephoneType, placeholder: localize(["secondary", "contact", "type"]), optionFilterProp: "children", filterOption: optionFilter, onChange: handleSecondaryTelephoneChange, open: secondaryVisible, onDropdownVisibleChange: setSecondaryVisible }, contactTypes.map((option) => (React.createElement(Option, { key: option.value + "secondary", value: option.value, id: "option-div-container" }, option.name)))),
                                        errors.secondaryTelephoneType &&
                                            touched.secondaryTelephoneType && (React.createElement("p", { className: "error" }, errors.secondaryTelephoneType))))),
                            React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", key: values.secondaryTelephoneType + "secondary" }, values.secondaryTelephoneType === TelephoneType.Mobile ? (React.createElement("div", { className: "form-group" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "secondaryMobileNumber" }, localize(["secondary", "telephone", "number"]))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.secondaryTelephoneNumber },
                                    React.createElement(Input, { name: "secondaryTelephoneNumber", placeholder: localize([
                                            "secondary",
                                            "telephone",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: (isEdit ? "" : "07") +
                                            values.secondaryTelephoneNumber, className: `${errors.secondaryTelephoneNumber &&
                                            touched.secondaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.secondaryTelephoneNumber &&
                                        touched.secondaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.secondaryTelephoneNumber))))) : (React.createElement("div", { className: "form-group", key: values.secondaryTelephoneType + "secondary" },
                                React.createElement("div", { className: "ant-col bold-text" },
                                    React.createElement("label", { "data-test": "secondaryTelephoneNumber" }, localize(["secondary", "telephone", "number"]))),
                                React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.primaryTelephoneNumber },
                                    React.createElement(Input, { name: "secondaryTelephoneNumber", placeholder: localize([
                                            "secondary",
                                            "telephone",
                                            "number",
                                        ]), onBlur: handleBlur, onChange: handleChange, defaultValue: values.secondaryTelephoneNumber, className: `${errors.secondaryTelephoneNumber &&
                                            touched.secondaryTelephoneNumber
                                            ? "input-error"
                                            : null}` }),
                                    errors.secondaryTelephoneNumber &&
                                        touched.secondaryTelephoneNumber && (React.createElement("p", { className: "error" }, errors.secondaryTelephoneNumber)))))))))),
            React.createElement("div", { className: "box-main-section" },
                React.createElement("section", { className: "box-section" },
                    React.createElement("div", { className: "box-title" },
                        React.createElement("div", { className: "box-heading" }, localize(["address", "information"]))),
                    React.createElement("div", { className: "box-description" },
                        React.createElement(Tabs, { defaultActiveKey: hide.status ? "1" : selectedTab, onChange: setSelectedTab, className: hide.status ? "printMode" : "" },
                            React.createElement(TabPane, { tab: localize(["registered", "address"]), key: "1" },
                                React.createElement("div", { className: hide.status ? "box-heading small" : "hidden" }, localize(["registered", "address"])),
                                React.createElement(RegisterAddress, { values: values, handleBlur: handleBlur, handleChange: handleChange, errors: errors, touched: touched, sameAddress: ((_c = values.addresses[0]) === null || _c === void 0 ? void 0 : _c.type) === 1, setFieldValue: setFieldValue, isReadOnly: isReadOnly }),
                                ((_d = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _d === void 0 ? void 0 : _d[1]) && ((_e = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _e === void 0 ? void 0 : _e[1]) ? (((_f = values.addresses[0]) === null || _f === void 0 ? void 0 : _f.type) === 1 &&
                                    ((_g = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _g === void 0 ? void 0 : _g[0]) &&
                                    ((_h = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _h === void 0 ? void 0 : _h[0]) ? (React.createElement("p", { className: "error" }, localize(["sameErrorsInContactAddress"]))) : (React.createElement("p", { className: "error" }, localize(["contactAddress", "isRequired!"])))) : null),
                            React.createElement(TabPane, { tab: localize(["contact", "address"]), key: hide.status ? "1" : "2" },
                                React.createElement("div", { className: hide.status ? "box-heading small" : "hidden" }, localize(["contact", "address"])),
                                React.createElement("div", { hidden: isReadOnly && ((_j = values.addresses[0]) === null || _j === void 0 ? void 0 : _j.type) !== 1, className: "mt-4" },
                                    React.createElement(Checkbox, { onChange: onSameAddressChange, name: `addresses.${0}.type`, className: ((_k = values.addresses[0]) === null || _k === void 0 ? void 0 : _k.type) !== 1 && hide.status
                                            ? "hidden"
                                            : "mb-2 ", defaultChecked: ((_l = values.addresses[0]) === null || _l === void 0 ? void 0 : _l.type) === 1, disabled: isReadOnly },
                                        " ",
                                        localize(["Company.sameAs", "registered", "address"]))),
                                React.createElement(ContactAddress, { values: values, handleBlur: handleBlur, handleChange: handleChange, setFieldValue: setFieldValue, errors: errors, touched: touched, isReadOnly: isReadOnly }),
                                ((_m = errors === null || errors === void 0 ? void 0 : errors.addresses) === null || _m === void 0 ? void 0 : _m[0]) && ((_o = touched === null || touched === void 0 ? void 0 : touched.addresses) === null || _o === void 0 ? void 0 : _o[0]) ? (React.createElement("p", { className: "error" }, localize(["registeredAddress", "isRequired!"]))) : null)))))),
        React.createElement(FormActionButtons, { onCancel: () => history.push(sitesRoute), fromId: "my-form", isEdit: !dirty || site.id > 0, isDirty: !dirty || isReadOnly, resetForm: () => {
                resetForm();
                setKey(Math.random());
            }, printOnClick: handlePrint, dataTest: "siteFormComponent", checkUnique: checkUniqueER })));
};
export default SiteForm;
