import React from "react";
import isEmpty from "../../../../../core/helpers/isEmpty";
import { COLUMN_HEADERS, ReportsVariables, } from "../../../../../core/constants/ReportConstants";
import localize from "../../../../../core/utils/translation-files";
export function ReportsListingColumns(responseColumns, isSummary = false, actions = null, reportType = null, reportId = null, isPrintMode = false, titleKey = null, timePeriod = null, getDataSheet) {
    var _a;
    const renderColumnValue = (index, value, record, childKey = "", isChild = false) => {
        var _a;
        let formattedValue = value;
        if (index === 0) {
            // if (record?.flag) formattedValue = `${value} (in 000s)`;
            if ((record === null || record === void 0 ? void 0 : record.chartUnit) === 1000) {
                formattedValue = `${value} (in K)`;
            }
            else if ((record === null || record === void 0 ? void 0 : record.chartUnit) === 1000000) {
                formattedValue = `${value} (in M)`;
            }
            return formattedValue;
        }
        else if (!isEmpty(value)) {
            if (childKey === "%Deviation")
                formattedValue = `${value}%`;
            else
                formattedValue = value;
            return (React.createElement("span", { style: Object.assign({}, (!isEmpty(formattedValue) &&
                    formattedValue[0] === "-" && { color: "red" })) }, !isEmpty(formattedValue) && formattedValue[0] === "-"
                ? `(${(_a = String(formattedValue)) === null || _a === void 0 ? void 0 : _a.slice(1)})`
                : // : value === null && isChild
                    // ? "No data"
                    formattedValue));
        }
    };
    const setWidth = (index) => {
        return !isPrintMode ? (index === 0 ? 360 : 125) : index === 0 ? 260 : 90;
    };
    return ((_a = responseColumns === null || responseColumns === void 0 ? void 0 : responseColumns.map((colName, index) => {
        var _a, _b, _c;
        const fieldName = Object.keys(colName)[0] === "monthNumber"
            ? Object.keys(colName)[1]
            : Object.keys(colName)[0];
        const childKeysSet = new Set((_b = (_a = COLUMN_HEADERS.find((header) => header.id === reportId)) === null || _a === void 0 ? void 0 : _a.headers) !== null && _b !== void 0 ? _b : []);
        return {
            fixed: index === 0 ? true : false,
            width: setWidth(index),
            columnWidth: setWidth(index),
            minWidth: setWidth(index),
            maxWidth: setWidth(index),
            fieldName,
            children: index === 0 || reportId <= ReportsVariables.BUDGET_VS_ACTUAL_ID
                ? undefined
                : (_c = Array.from(childKeysSet)) === null || _c === void 0 ? void 0 : _c.map((childKey) => ({
                    title: React.createElement("span", null, localize([`Report.${childKey}`])),
                    dataIndex: [fieldName, childKey],
                    key: `${fieldName}-${childKey}`,
                    width: setWidth(index),
                    minWidth: setWidth(index),
                    render: (value, record) => (React.createElement("span", { className: `reportColumnValue ${index !== 0 &&
                            (record === null || record === void 0 ? void 0 : record.underline) &&
                            !isEmpty(value) &&
                            `netValue`} ${(record === null || record === void 0 ? void 0 : record.bold) && "boldRow"}` }, renderColumnValue(index, value, record, childKey, !(record === null || record === void 0 ? void 0 : record.category)))),
                })),
            title: index > 0 && (React.createElement(React.Fragment, null,
                React.createElement("span", { style: { display: "block", fontSize: "11px" } },
                    "Month ",
                    colName["monthNumber"]),
                React.createElement("span", null, colName[fieldName]),
                React.createElement("span", { hidden: titleKey === "summarySheetRatio" ||
                        reportId >= ReportsVariables.BUDGET_VS_ACTUAL_ID
                        ? true
                        : false, style: { display: "block", fontWeight: "lighter" } }, "'000'"))),
            render: (value, record) => (React.createElement(React.Fragment, null,
                React.createElement("span", { className: `reportColumnValue ${index !== 0 &&
                        (record === null || record === void 0 ? void 0 : record.underline) &&
                        !isEmpty(value) &&
                        `netValue`} ${(record === null || record === void 0 ? void 0 : record.bold) && "boldRow"}` }, renderColumnValue(index, value, record)),
                React.createElement("span", { className: `reports-chart-icon-${record === null || record === void 0 ? void 0 : record.type}`, id: !(record === null || record === void 0 ? void 0 : record.category) &&
                        // isSummary &&
                        (record === null || record === void 0 ? void 0 : record.type) === reportType &&
                        "selectedRowId" }, isSummary &&
                    actions &&
                    index === 0 &&
                    !(record === null || record === void 0 ? void 0 : record.category) &&
                    actions(record, value)))),
        };
    })) !== null && _a !== void 0 ? _a : []);
}
