import React from "react";
export const largeGraphIcon = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "24", height: "24", viewBox: "0 0 24 24", fill: "none" },
    React.createElement("path", { d: "M3 13C3 12.448 3.448 12 4 12", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M3 16V17", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M4 21C3.448 21 3 20.552 3 20", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M7 21H8", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M7 12H8", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M12 20C12 20.552 11.552 21 11 21", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M12 17V16", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M11 12C11.552 12 12 12.448 12 13", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M7 12V5C7 3.895 7.895 3 9 3H19C20.105 3 21 3.895 21 5V15C21 16.105 20.105 17 19 17H12", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M14 10L18 6", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" }),
    React.createElement("path", { d: "M15 6H18V9", stroke: "white", "stroke-width": "1.5", "stroke-linecap": "round", "stroke-linejoin": "round" })));
export const smallHistoricalScale = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "20", height: "120", viewBox: "0 0 20 120", fill: "none" },
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 0)", fill: "#00B050" }),
    React.createElement("path", { d: "M10.2305 11.9277L7.91016 19H6.04102L9.21094 10.4688H10.4004L10.2305 11.9277ZM12.1582 19L9.83203 11.9277L9.64453 10.4688H10.8457L14.0332 19H12.1582ZM12.0527 15.8242V17.2012H7.54688V15.8242H12.0527Z", fill: "black" }),
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 30)", fill: "#92D050" }),
    React.createElement("path", { d: "M10.1016 45.2559H7.91016L7.89844 44.0488H9.73828C10.0625 44.0488 10.3281 44.0078 10.5352 43.9258C10.7422 43.8398 10.8965 43.7168 10.998 43.5566C11.1035 43.3926 11.1562 43.1934 11.1562 42.959C11.1562 42.6934 11.1055 42.4785 11.0039 42.3145C10.9062 42.1504 10.752 42.0312 10.541 41.957C10.334 41.8828 10.0664 41.8457 9.73828 41.8457H8.51953V49H6.76172V40.4688H9.73828C10.2344 40.4688 10.6777 40.5156 11.0684 40.6094C11.4629 40.7031 11.7969 40.8457 12.0703 41.0371C12.3438 41.2285 12.5527 41.4707 12.6973 41.7637C12.8418 42.0527 12.9141 42.3965 12.9141 42.7949C12.9141 43.1465 12.834 43.4707 12.6738 43.7676C12.5176 44.0645 12.2695 44.3066 11.9297 44.4941C11.5938 44.6816 11.1543 44.7852 10.6113 44.8047L10.1016 45.2559ZM10.0254 49H7.42969L8.11523 47.6289H10.0254C10.334 47.6289 10.5859 47.5801 10.7812 47.4824C10.9766 47.3809 11.1211 47.2441 11.2148 47.0723C11.3086 46.9004 11.3555 46.7031 11.3555 46.4805C11.3555 46.2305 11.3125 46.0137 11.2266 45.8301C11.1445 45.6465 11.0117 45.5059 10.8281 45.4082C10.6445 45.3066 10.4023 45.2559 10.1016 45.2559H8.4082L8.41992 44.0488H10.5293L10.9336 44.5234C11.4531 44.5156 11.8711 44.6074 12.1875 44.7988C12.5078 44.9863 12.7402 45.2305 12.8848 45.5312C13.0332 45.832 13.1074 46.1543 13.1074 46.498C13.1074 47.0449 12.9883 47.5059 12.75 47.8809C12.5117 48.252 12.1621 48.5312 11.7012 48.7188C11.2441 48.9062 10.6855 49 10.0254 49Z", fill: "black" }),
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 60)", fill: "#FFC000" }),
    React.createElement("path", { d: "M11.707 76.1699H13.459C13.4238 76.7441 13.2656 77.2539 12.9844 77.6992C12.707 78.1445 12.3184 78.4922 11.8184 78.7422C11.3223 78.9922 10.7246 79.1172 10.0254 79.1172C9.47852 79.1172 8.98828 79.0234 8.55469 78.8359C8.12109 78.6445 7.75 78.3711 7.44141 78.0156C7.13672 77.6602 6.9043 77.2305 6.74414 76.7266C6.58398 76.2227 6.50391 75.6582 6.50391 75.0332V74.4414C6.50391 73.8164 6.58594 73.252 6.75 72.748C6.91797 72.2402 7.15625 71.8086 7.46484 71.4531C7.77734 71.0977 8.15039 70.8242 8.58398 70.6328C9.01758 70.4414 9.50195 70.3457 10.0371 70.3457C10.748 70.3457 11.3477 70.4746 11.8359 70.7324C12.3281 70.9902 12.709 71.3457 12.9785 71.7988C13.252 72.252 13.416 72.7676 13.4707 73.3457H11.7129C11.6934 73.002 11.625 72.7109 11.5078 72.4727C11.3906 72.2305 11.2129 72.0488 10.9746 71.9277C10.7402 71.8027 10.4277 71.7402 10.0371 71.7402C9.74414 71.7402 9.48828 71.7949 9.26953 71.9043C9.05078 72.0137 8.86719 72.1797 8.71875 72.4023C8.57031 72.625 8.45898 72.9062 8.38477 73.2461C8.31445 73.582 8.2793 73.9766 8.2793 74.4297V75.0332C8.2793 75.4746 8.3125 75.8633 8.37891 76.1992C8.44531 76.5312 8.54688 76.8125 8.68359 77.043C8.82422 77.2695 9.00391 77.4414 9.22266 77.5586C9.44531 77.6719 9.71289 77.7285 10.0254 77.7285C10.3926 77.7285 10.6953 77.6699 10.9336 77.5527C11.1719 77.4355 11.3535 77.2617 11.4785 77.0312C11.6074 76.8008 11.6836 76.5137 11.707 76.1699Z", fill: "black" }),
    React.createElement("rect", { width: "20", height: "30", transform: "matrix(-1 0 0 1 20 90)", fill: "#FF0000" }),
    React.createElement("path", { d: "M9.38086 109H7.52344L7.53516 107.629H9.38086C9.8418 107.629 10.2305 107.525 10.5469 107.318C10.8633 107.107 11.1016 106.801 11.2617 106.398C11.4258 105.996 11.5078 105.51 11.5078 104.939V104.523C11.5078 104.086 11.4609 103.701 11.3672 103.369C11.2773 103.037 11.1426 102.758 10.9629 102.531C10.7832 102.305 10.5625 102.135 10.3008 102.021C10.0391 101.904 9.73828 101.846 9.39844 101.846H7.48828V100.469H9.39844C9.96875 100.469 10.4902 100.566 10.9629 100.762C11.4395 100.953 11.8516 101.229 12.1992 101.588C12.5469 101.947 12.8145 102.377 13.002 102.877C13.1934 103.373 13.2891 103.926 13.2891 104.535V104.939C13.2891 105.545 13.1934 106.098 13.002 106.598C12.8145 107.098 12.5469 107.527 12.1992 107.887C11.8555 108.242 11.4434 108.518 10.9629 108.713C10.4863 108.904 9.95898 109 9.38086 109ZM8.51953 100.469V109H6.76172V100.469H8.51953Z", fill: "black" })));
export const largeHistoricalScale = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "40", height: "400", viewBox: "0 0 40 400", fill: "none" },
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 0)", fill: "#00B050" }),
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 100)", fill: "#92D050" }),
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 200)", fill: "#FFC000" }),
    React.createElement("rect", { width: "40", height: "100", transform: "matrix(-1 0 0 1 40 300)", fill: "#FF0000" }),
    React.createElement("path", { d: "M19.9355 46.749L17.2285 55H15.0479L18.7461 45.0469H20.1338L19.9355 46.749ZM22.1846 55L19.4707 46.749L19.252 45.0469H20.6533L24.3721 55H22.1846ZM22.0615 51.2949V52.9014H16.8047V51.2949H22.0615Z", fill: "black" }),
    React.createElement("path", { d: "M20.7852 150.632H18.2285L18.2148 149.224H20.3613C20.7396 149.224 21.0495 149.176 21.291 149.08C21.5326 148.98 21.7126 148.836 21.8311 148.649C21.9541 148.458 22.0156 148.226 22.0156 147.952C22.0156 147.642 21.9564 147.392 21.8379 147.2C21.724 147.009 21.5439 146.87 21.2979 146.783C21.0563 146.697 20.7441 146.653 20.3613 146.653H18.9395V155H16.8887V145.047H20.3613C20.9401 145.047 21.4574 145.102 21.9131 145.211C22.3734 145.32 22.763 145.487 23.082 145.71C23.401 145.933 23.6449 146.216 23.8135 146.558C23.9821 146.895 24.0664 147.296 24.0664 147.761C24.0664 148.171 23.973 148.549 23.7861 148.896C23.6038 149.242 23.3145 149.524 22.918 149.743C22.526 149.962 22.0133 150.083 21.3799 150.105L20.7852 150.632ZM20.6963 155H17.668L18.4678 153.4H20.6963C21.0563 153.4 21.3503 153.343 21.5781 153.229C21.806 153.111 21.9746 152.951 22.084 152.751C22.1934 152.55 22.248 152.32 22.248 152.061C22.248 151.769 22.1979 151.516 22.0977 151.302C22.002 151.088 21.847 150.924 21.6328 150.81C21.4186 150.691 21.1361 150.632 20.7852 150.632H18.8096L18.8232 149.224H21.2842L21.7559 149.777C22.362 149.768 22.8496 149.875 23.2188 150.099C23.5924 150.317 23.8636 150.602 24.0322 150.953C24.2054 151.304 24.292 151.68 24.292 152.081C24.292 152.719 24.153 153.257 23.875 153.694C23.597 154.127 23.1891 154.453 22.6514 154.672C22.1182 154.891 21.4665 155 20.6963 155Z", fill: "black" }),
    React.createElement("path", { d: "M21.6582 251.698H23.7021C23.6611 252.368 23.4766 252.963 23.1484 253.482C22.8249 254.002 22.3714 254.408 21.7881 254.699C21.2093 254.991 20.512 255.137 19.6963 255.137C19.0583 255.137 18.4863 255.027 17.9805 254.809C17.4746 254.585 17.0417 254.266 16.6816 253.852C16.3262 253.437 16.055 252.936 15.8682 252.348C15.6813 251.76 15.5879 251.101 15.5879 250.372V249.682C15.5879 248.952 15.6836 248.294 15.875 247.706C16.071 247.114 16.349 246.61 16.709 246.195C17.0736 245.781 17.5088 245.462 18.0146 245.238C18.5205 245.015 19.0856 244.903 19.71 244.903C20.5394 244.903 21.2389 245.054 21.8086 245.354C22.3828 245.655 22.8271 246.07 23.1416 246.599C23.4606 247.127 23.652 247.729 23.7158 248.403H21.665C21.6423 248.002 21.5625 247.663 21.4258 247.385C21.2891 247.102 21.0817 246.89 20.8037 246.749C20.5303 246.603 20.1657 246.53 19.71 246.53C19.3682 246.53 19.0697 246.594 18.8145 246.722C18.5592 246.849 18.3451 247.043 18.1719 247.303C17.9987 247.562 17.8688 247.891 17.7822 248.287C17.7002 248.679 17.6592 249.139 17.6592 249.668V250.372C17.6592 250.887 17.6979 251.34 17.7754 251.732C17.8529 252.12 17.9714 252.448 18.1309 252.717C18.2949 252.981 18.5046 253.182 18.7598 253.318C19.0195 253.451 19.3317 253.517 19.6963 253.517C20.1247 253.517 20.4779 253.448 20.7559 253.312C21.0339 253.175 21.2458 252.972 21.3916 252.703C21.542 252.434 21.6309 252.099 21.6582 251.698Z", fill: "black" }),
    React.createElement("path", { d: "M18.9443 355H16.7773L16.791 353.4H18.9443C19.4821 353.4 19.9355 353.28 20.3047 353.038C20.6738 352.792 20.9518 352.434 21.1387 351.965C21.3301 351.495 21.4258 350.928 21.4258 350.263V349.777C21.4258 349.267 21.3711 348.818 21.2617 348.431C21.1569 348.043 20.9997 347.717 20.79 347.453C20.5804 347.189 20.3229 346.991 20.0176 346.858C19.7122 346.722 19.3613 346.653 18.9648 346.653H16.7363V345.047H18.9648C19.6302 345.047 20.2386 345.161 20.79 345.389C21.346 345.612 21.8268 345.933 22.2324 346.353C22.638 346.772 22.9502 347.273 23.1689 347.856C23.3923 348.435 23.5039 349.08 23.5039 349.791V350.263C23.5039 350.969 23.3923 351.614 23.1689 352.197C22.9502 352.781 22.638 353.282 22.2324 353.701C21.8314 354.116 21.3506 354.437 20.79 354.665C20.234 354.888 19.6188 355 18.9443 355ZM17.9395 345.047V355H15.8887V345.047H17.9395Z", fill: "black" })));
export const AtoB = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1415_3492)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "1.5", y1: "22.5", x2: "264.5", y2: "22.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11", y1: "9", x2: "11", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46", y1: "9", x2: "46", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81", y1: "9", x2: "81", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116", y1: "9", x2: "116", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151", y1: "9", x2: "151", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186", y1: "9", x2: "186", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221", y1: "9", x2: "221", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256", y1: "9", x2: "256", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "36.5", x2: "264.5", y2: "36.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "50.5", x2: "264.5", y2: "50.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "64.5", x2: "264.5", y2: "64.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "78.5", x2: "264.5", y2: "78.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "92.5", x2: "264.5", y2: "92.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "106.5", x2: "264.5", y2: "106.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M14 28H47V106H14V28Z", fill: "#00B050" }),
            React.createElement("path", { d: "M47 28V106H54V29.6083L47 28Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M63 45H96V106H63V45Z", fill: "#92D050" }),
            React.createElement("path", { d: "M96 45V106H105V46.2577L96 45Z", fill: "#639C26" }),
            React.createElement("path", { d: "M112 60H144V106H112V60Z", fill: "#92D050" }),
            React.createElement("path", { d: "M144 60V106H151V60.9484L144 60Z", fill: "#639C26" }),
            React.createElement("path", { d: "M161 76H194V106H161V76Z", fill: "#92D050" }),
            React.createElement("path", { d: "M194 76V106H201V76.6185L194 76Z", fill: "#639C26" }),
            React.createElement("path", { d: "M210 89H242V106H210V89Z", fill: "#92D050" }),
            React.createElement("path", { d: "M242 89V106H250V89.3505L242 89Z", fill: "#639C26" }),
            React.createElement("path", { d: "M27.374 23L31.742 13.2H33.982L38.364 23H35.984L32.4 14.348H33.296L29.698 23H27.374ZM29.558 20.9L30.16 19.178H35.2L35.816 20.9H29.558Z", fill: "white" }),
            React.createElement("path", { d: "M222.662 82V72.2H227.45C228.682 72.2 229.606 72.4333 230.222 72.9C230.847 73.3667 231.16 73.9827 231.16 74.748C231.16 75.2613 231.034 75.7093 230.782 76.092C230.53 76.4653 230.185 76.7547 229.746 76.96C229.307 77.1653 228.803 77.268 228.234 77.268L228.5 76.694C229.116 76.694 229.662 76.7967 230.138 77.002C230.614 77.198 230.983 77.492 231.244 77.884C231.515 78.276 231.65 78.7567 231.65 79.326C231.65 80.166 231.319 80.824 230.656 81.3C229.993 81.7667 229.018 82 227.73 82H222.662ZM224.916 80.292H227.562C228.15 80.292 228.593 80.1987 228.892 80.012C229.2 79.816 229.354 79.508 229.354 79.088C229.354 78.6773 229.2 78.374 228.892 78.178C228.593 77.9727 228.15 77.87 227.562 77.87H224.748V76.218H227.17C227.721 76.218 228.141 76.1247 228.43 75.938C228.729 75.742 228.878 75.448 228.878 75.056C228.878 74.6733 228.729 74.3887 228.43 74.202C228.141 74.006 227.721 73.908 227.17 73.908H224.916V80.292Z", fill: "white" }),
            React.createElement("g", { filter: "url(#filter0_d_1415_3492)" },
                React.createElement("path", { d: "M39.0271 53.082C46.02 57.76 50.3855 60.594 53.5416 62.4379C60.8207 66.6906 68.5847 70.9432 77.1091 74.7706C82.593 77.2329 87.7748 79.6508 93.1551 81.5749C99.802 83.7013 103.712 85.4023 114.744 87.9539C129.286 90.9308 136.687 91.3208 150.346 92.5033C176.394 94.7582 193.296 92.5403 194.872 92.5033C194.941 89.3857 194.872 91.3885 194.872 87.9165C203.862 91.9197 206.32 92.7347 216.007 96.4618C206.873 100.646 203.942 101.507 194.872 105.662C194.748 102.962 194.749 103.733 194.749 100.433C191.101 100.091 171.881 100.909 153.856 99.5824C140.317 98.5856 130.658 97.7546 114.744 93.4824C108.006 91.6736 101.307 89.4263 94.0569 86.608C89.2509 84.7398 82.5282 81.4216 77.6106 79.0233C68.5022 74.5813 63.9432 71.501 54.043 64.5611C49.8447 61.6182 46.0472 58.6105 39.0271 53.082Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1415_3492", x: "35.0271", y: "53.082", width: "184.98", height: "60.5781", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1415_3492" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1415_3492", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1415_3492" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
export const BtoC = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1336_1614)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "1.5", y1: "23.5", x2: "264.5", y2: "23.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11", y1: "10", x2: "11", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46", y1: "10", x2: "46", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81", y1: "10", x2: "81", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116", y1: "10", x2: "116", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151", y1: "10", x2: "151", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186", y1: "10", x2: "186", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221", y1: "10", x2: "221", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256", y1: "10", x2: "256", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "37.5", x2: "264.5", y2: "37.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "51.5", x2: "264.5", y2: "51.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "65.5", x2: "264.5", y2: "65.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "79.5", x2: "264.5", y2: "79.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "93.5", x2: "264.5", y2: "93.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "107.5", x2: "264.5", y2: "107.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M14 29H47V107H14V29Z", fill: "#92D050" }),
            React.createElement("path", { d: "M47 29V107H54V30.6083L47 29Z", fill: "#639C26" }),
            React.createElement("path", { d: "M62 46H95V107H62V46Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M95 46V107H104V47.2577L95 46Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M113 61H145V107H113V61Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M145 61V107H152V61.9484L145 61Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M161 77H194V107H161V77Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M194 77V107H201V77.6185L194 77Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M211 90H243V107H211V90Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M243 90V107H251V90.3505L243 90Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M28.162 23V13.2H32.95C34.182 13.2 35.106 13.4333 35.722 13.9C36.3473 14.3667 36.66 14.9827 36.66 15.748C36.66 16.2613 36.534 16.7093 36.282 17.092C36.03 17.4653 35.6847 17.7547 35.246 17.96C34.8073 18.1653 34.3033 18.268 33.734 18.268L34 17.694C34.616 17.694 35.162 17.7967 35.638 18.002C36.114 18.198 36.4827 18.492 36.744 18.884C37.0147 19.276 37.15 19.7567 37.15 20.326C37.15 21.166 36.8187 21.824 36.156 22.3C35.4933 22.7667 34.518 23 33.23 23H28.162ZM30.416 21.292H33.062C33.65 21.292 34.0933 21.1987 34.392 21.012C34.7 20.816 34.854 20.508 34.854 20.088C34.854 19.6773 34.7 19.374 34.392 19.178C34.0933 18.9727 33.65 18.87 33.062 18.87H30.248V17.218H32.67C33.2207 17.218 33.6407 17.1247 33.93 16.938C34.2287 16.742 34.378 16.448 34.378 16.056C34.378 15.6733 34.2287 15.3887 33.93 15.202C33.6407 15.006 33.2207 14.908 32.67 14.908H30.416V21.292Z", fill: "white" }),
            React.createElement("path", { d: "M225.852 89.168C225.096 89.168 224.391 89.0467 223.738 88.804C223.094 88.552 222.534 88.1973 222.058 87.74C221.582 87.2827 221.209 86.746 220.938 86.13C220.677 85.514 220.546 84.8373 220.546 84.1C220.546 83.3627 220.677 82.686 220.938 82.07C221.209 81.454 221.582 80.9173 222.058 80.46C222.543 80.0027 223.108 79.6527 223.752 79.41C224.396 79.158 225.101 79.032 225.866 79.032C226.715 79.032 227.481 79.1813 228.162 79.48C228.853 79.7693 229.431 80.1987 229.898 80.768L228.442 82.112C228.106 81.7293 227.733 81.4447 227.322 81.258C226.911 81.062 226.463 80.964 225.978 80.964C225.521 80.964 225.101 81.0387 224.718 81.188C224.335 81.3373 224.004 81.552 223.724 81.832C223.444 82.112 223.225 82.4433 223.066 82.826C222.917 83.2087 222.842 83.6333 222.842 84.1C222.842 84.5667 222.917 84.9913 223.066 85.374C223.225 85.7567 223.444 86.088 223.724 86.368C224.004 86.648 224.335 86.8627 224.718 87.012C225.101 87.1613 225.521 87.236 225.978 87.236C226.463 87.236 226.911 87.1427 227.322 86.956C227.733 86.76 228.106 86.466 228.442 86.074L229.898 87.418C229.431 87.9873 228.853 88.4213 228.162 88.72C227.481 89.0187 226.711 89.168 225.852 89.168Z", fill: "white" }),
            React.createElement("g", { filter: "url(#filter0_d_1336_1614)" },
                React.createElement("path", { d: "M39.0994 49.5859C46.0925 54.264 50.4581 57.0981 53.6142 58.942C60.8934 63.1948 68.6575 67.4476 77.1821 71.275C82.6661 73.7373 87.8479 76.1553 93.2284 78.0795C99.8754 80.2058 103.786 81.907 114.818 84.4586C129.36 87.4355 136.761 87.8256 150.42 89.008C176.468 91.263 193.371 89.045 194.947 89.008C195.016 85.8904 194.947 87.8932 194.947 84.4212C203.937 88.4245 206.396 89.2395 216.082 92.9667C206.948 97.1514 204.017 98.0122 194.947 102.167C194.823 99.4666 194.824 100.238 194.824 96.9379C191.176 96.596 171.955 97.4142 153.93 96.0874C140.391 95.0905 130.731 94.2595 114.818 89.9872C108.079 88.1783 101.381 85.931 94.1302 83.1126C89.3241 81.2445 82.6013 77.9261 77.6835 75.5278C68.575 71.0857 64.0159 68.0053 54.1156 61.0653C49.9172 58.1223 46.1197 55.1145 39.0994 49.5859Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1336_1614", x: "35.0994", y: "49.5859", width: "184.983", height: "60.582", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1336_1614" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1336_1614", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1336_1614" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
export const CtoD = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1415_3396)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "1.5", y1: "19.5", x2: "264.5", y2: "19.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11", y1: "6", x2: "11", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46", y1: "6", x2: "46", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81", y1: "6", x2: "81", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116", y1: "6", x2: "116", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151", y1: "6", x2: "151", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186", y1: "6", x2: "186", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221", y1: "6", x2: "221", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256", y1: "6", x2: "256", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "33.5", x2: "264.5", y2: "33.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "47.5", x2: "264.5", y2: "47.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "61.5", x2: "264.5", y2: "61.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "75.5", x2: "264.5", y2: "75.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "89.5", x2: "264.5", y2: "89.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "103.5", x2: "264.5", y2: "103.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M14 25H47V103H14V25Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M47 25V103H54V26.6083L47 25Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M63 42H96V103H63V42Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M96 42V103H105V43.2577L96 42Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M112 57H144V103H112V57Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M144 57V103H151V57.9484L144 57Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M161 73H194V103H161V73Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M194 73V103H201V73.6185L194 73Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M210 86H242V103H210V86Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M241.593 86V103H249.593V86.3505L241.593 86Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M33.352 19.1602C32.596 19.1602 31.8913 19.0389 31.238 18.7962C30.594 18.5442 30.034 18.1895 29.558 17.7322C29.082 17.2749 28.7087 16.7382 28.438 16.1222C28.1767 15.5062 28.046 14.8295 28.046 14.0922C28.046 13.3549 28.1767 12.6782 28.438 12.0622C28.7087 11.4462 29.082 10.9095 29.558 10.4522C30.0433 9.99485 30.608 9.64485 31.252 9.40219C31.896 9.15019 32.6007 9.02419 33.366 9.02419C34.2153 9.02419 34.9807 9.17352 35.662 9.47219C36.3527 9.76152 36.9313 10.1909 37.398 10.7602L35.942 12.1042C35.606 11.7215 35.2327 11.4369 34.822 11.2502C34.4113 11.0542 33.9633 10.9562 33.478 10.9562C33.0207 10.9562 32.6007 11.0309 32.218 11.1802C31.8353 11.3295 31.504 11.5442 31.224 11.8242C30.944 12.1042 30.7247 12.4355 30.566 12.8182C30.4167 13.2009 30.342 13.6255 30.342 14.0922C30.342 14.5589 30.4167 14.9835 30.566 15.3662C30.7247 15.7489 30.944 16.0802 31.224 16.3602C31.504 16.6402 31.8353 16.8549 32.218 17.0042C32.6007 17.1535 33.0207 17.2282 33.478 17.2282C33.9633 17.2282 34.4113 17.1349 34.822 16.9482C35.2327 16.7522 35.606 16.4582 35.942 16.0662L37.398 17.4102C36.9313 17.9795 36.3527 18.4135 35.662 18.7122C34.9807 19.0109 34.2107 19.1602 33.352 19.1602Z", fill: "white" }),
            React.createElement("path", { d: "M222.662 81.332V71.532H227.114C228.178 71.532 229.116 71.7374 229.928 72.148C230.74 72.5494 231.375 73.114 231.832 73.842C232.289 74.57 232.518 75.4334 232.518 76.432C232.518 77.4214 232.289 78.2847 231.832 79.022C231.375 79.75 230.74 80.3194 229.928 80.73C229.116 81.1314 228.178 81.332 227.114 81.332H222.662ZM224.93 79.47H227.002C227.655 79.47 228.22 79.3487 228.696 79.106C229.181 78.854 229.555 78.4994 229.816 78.042C230.087 77.5847 230.222 77.048 230.222 76.432C230.222 75.8067 230.087 75.27 229.816 74.822C229.555 74.3647 229.181 74.0147 228.696 73.772C228.22 73.52 227.655 73.394 227.002 73.394H224.93V79.47Z", fill: "white" }),
            React.createElement("g", { filter: "url(#filter0_d_1415_3396)" },
                React.createElement("path", { d: "M39.0271 50.082C46.02 54.76 50.3855 57.594 53.5416 59.4379C60.8207 63.6906 68.5847 67.9432 77.1091 71.7706C82.593 74.2329 87.7748 76.6508 93.1551 78.5749C99.802 80.7013 103.712 82.4023 114.744 84.9539C129.286 87.9308 136.687 88.3208 150.346 89.5033C176.394 91.7582 193.296 89.5403 194.872 89.5033C194.941 86.3857 194.872 88.3885 194.872 84.9165C203.862 88.9197 206.32 89.7347 216.007 93.4618C206.873 97.6464 203.942 98.5072 194.872 102.662C194.748 99.9616 194.749 100.733 194.749 97.4329C191.101 97.091 171.881 97.9092 153.856 96.5824C140.317 95.5856 130.658 94.7546 114.744 90.4824C108.006 88.6736 101.307 86.4263 94.0569 83.608C89.2509 81.7398 82.5282 78.4216 77.6106 76.0233C68.5022 71.5813 63.9432 68.501 54.043 61.5611C49.8447 58.6182 46.0472 55.6105 39.0271 50.082Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1415_3396", x: "35.0271", y: "50.082", width: "184.98", height: "60.5781", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1415_3396" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1415_3396", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1415_3396" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
export const DtoC = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "121", viewBox: "0 0 265 121", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_72_2707)" },
            React.createElement("path", { d: "M265 0H0V121H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "1.5", y1: "19.5", x2: "264.5", y2: "19.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11", y1: "6", x2: "11", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46", y1: "6", x2: "46", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81", y1: "6", x2: "81", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116", y1: "6", x2: "116", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151", y1: "6", x2: "151", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186", y1: "6", x2: "186", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221", y1: "6", x2: "221", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256", y1: "6", x2: "256", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "33.5", x2: "264.5", y2: "33.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "47.5", x2: "264.5", y2: "47.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "61.5", x2: "264.5", y2: "61.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "75.5", x2: "264.5", y2: "75.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "89.5", x2: "264.5", y2: "89.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "1.5", y1: "103.5", x2: "264.5", y2: "103.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M23.162 83V73.2H27.614C28.678 73.2 29.616 73.4053 30.428 73.816C31.24 74.2173 31.8747 74.782 32.332 75.51C32.7893 76.238 33.018 77.1013 33.018 78.1C33.018 79.0893 32.7893 79.9527 32.332 80.69C31.8747 81.418 31.24 81.9873 30.428 82.398C29.616 82.7993 28.678 83 27.614 83H23.162ZM25.43 81.138H27.502C28.1553 81.138 28.72 81.0167 29.196 80.774C29.6813 80.522 30.0547 80.1673 30.316 79.71C30.5867 79.2527 30.722 78.716 30.722 78.1C30.722 77.4747 30.5867 76.938 30.316 76.49C30.0547 76.0327 29.6813 75.6827 29.196 75.44C28.72 75.188 28.1553 75.062 27.502 75.062H25.43V81.138Z", fill: "white" }),
            React.createElement("path", { d: "M210 25H243V103H210V25Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M243 25V103H251V26.6083L243 25Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M161 43H194V103H161V43Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M194 43V103H201V44.2371L194 43Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M112 57H145V103H112V57Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M145 57V103H152V57.9484L145 57Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M63 73H96V103H63V73Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M96 73V103H103V73.6185L96 73Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M14 86H47V103H14V86Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M47 86V103H54V86.3505L47 86Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M231.852 19.168C231.096 19.168 230.391 19.0467 229.738 18.804C229.094 18.552 228.534 18.1973 228.058 17.74C227.582 17.2827 227.209 16.746 226.938 16.13C226.677 15.514 226.546 14.8373 226.546 14.1C226.546 13.3627 226.677 12.686 226.938 12.07C227.209 11.454 227.582 10.9173 228.058 10.46C228.543 10.0027 229.108 9.65267 229.752 9.41C230.396 9.158 231.101 9.032 231.866 9.032C232.715 9.032 233.481 9.18133 234.162 9.48C234.853 9.76933 235.431 10.1987 235.898 10.768L234.442 12.112C234.106 11.7293 233.733 11.4447 233.322 11.258C232.911 11.062 232.463 10.964 231.978 10.964C231.521 10.964 231.101 11.0387 230.718 11.188C230.335 11.3373 230.004 11.552 229.724 11.832C229.444 12.112 229.225 12.4433 229.066 12.826C228.917 13.2087 228.842 13.6333 228.842 14.1C228.842 14.5667 228.917 14.9913 229.066 15.374C229.225 15.7567 229.444 16.088 229.724 16.368C230.004 16.648 230.335 16.8627 230.718 17.012C231.101 17.1613 231.521 17.236 231.978 17.236C232.463 17.236 232.911 17.1427 233.322 16.956C233.733 16.76 234.106 16.466 234.442 16.074L235.898 17.418C235.431 17.9873 234.853 18.4213 234.162 18.72C233.481 19.0187 232.711 19.168 231.852 19.168Z", fill: "white" }),
            React.createElement("g", { filter: "url(#filter0_d_72_2707)" },
                React.createElement("path", { d: "M221 42.5C144.6 96.1 56.5 100.833 22 96.5C124 112.5 201.5 72.1667 227.5 50L232.5 55.5L238 35H216L221 42.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_72_2707", x: "18", y: "35", width: "224", height: "73.1602", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_72_2707" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_72_2707", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_72_2707" },
                React.createElement("rect", { width: "265", height: "121", fill: "white" })))));
};
export const CtoB = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "266", height: "121", viewBox: "0 0 266 121", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_72_2749)" },
            React.createElement("path", { d: "M265.5 0H0.5V121H265.5V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "2", y1: "19.5", x2: "265", y2: "19.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11.5", y1: "6", x2: "11.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46.5", y1: "6", x2: "46.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81.5", y1: "6", x2: "81.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116.5", y1: "6", x2: "116.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151.5", y1: "6", x2: "151.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186.5", y1: "6", x2: "186.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221.5", y1: "6", x2: "221.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256.5", y1: "6", x2: "256.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "33.5", x2: "265", y2: "33.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "47.5", x2: "265", y2: "47.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "61.5", x2: "265", y2: "61.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "75.5", x2: "265", y2: "75.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "89.5", x2: "265", y2: "89.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "103.5", x2: "265", y2: "103.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M28.852 83.168C28.096 83.168 27.3913 83.0467 26.738 82.804C26.094 82.552 25.534 82.1973 25.058 81.74C24.582 81.2827 24.2087 80.746 23.938 80.13C23.6767 79.514 23.546 78.8373 23.546 78.1C23.546 77.3627 23.6767 76.686 23.938 76.07C24.2087 75.454 24.582 74.9173 25.058 74.46C25.5433 74.0027 26.108 73.6527 26.752 73.41C27.396 73.158 28.1007 73.032 28.866 73.032C29.7153 73.032 30.4807 73.1813 31.162 73.48C31.8527 73.7693 32.4313 74.1987 32.898 74.768L31.442 76.112C31.106 75.7293 30.7327 75.4447 30.322 75.258C29.9113 75.062 29.4633 74.964 28.978 74.964C28.5207 74.964 28.1007 75.0387 27.718 75.188C27.3353 75.3373 27.004 75.552 26.724 75.832C26.444 76.112 26.2247 76.4433 26.066 76.826C25.9167 77.2087 25.842 77.6333 25.842 78.1C25.842 78.5667 25.9167 78.9913 26.066 79.374C26.2247 79.7567 26.444 80.088 26.724 80.368C27.004 80.648 27.3353 80.8627 27.718 81.012C28.1007 81.1613 28.5207 81.236 28.978 81.236C29.4633 81.236 29.9113 81.1427 30.322 80.956C30.7327 80.76 31.106 80.466 31.442 80.074L32.898 81.418C32.4313 81.9873 31.8527 82.4213 31.162 82.72C30.4807 83.0187 29.7107 83.168 28.852 83.168Z", fill: "white" }),
            React.createElement("path", { d: "M211 25H244V103H211V25Z", fill: "#92D050" }),
            React.createElement("path", { d: "M244 25V103H251V26.6083L244 25Z", fill: "#639C26" }),
            React.createElement("path", { d: "M162 43H194V103H162V43Z", fill: "#92D050" }),
            React.createElement("path", { d: "M194 43V103H201V44.2371L194 43Z", fill: "#639C26" }),
            React.createElement("path", { d: "M113 57H145V103H113V57Z", fill: "#92D050" }),
            React.createElement("path", { d: "M145 57V103H153V57.9484L145 57Z", fill: "#639C26" }),
            React.createElement("path", { d: "M64 73H96V103H64V73Z", fill: "#92D050" }),
            React.createElement("path", { d: "M96 73V103H104V73.6185L96 73Z", fill: "#639C26" }),
            React.createElement("path", { d: "M15 86H47V103H15V86Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M47 86V103H55V86.3505L47 86Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M226.162 19V9.2H230.95C232.182 9.2 233.106 9.43333 233.722 9.9C234.347 10.3667 234.66 10.9827 234.66 11.748C234.66 12.2613 234.534 12.7093 234.282 13.092C234.03 13.4653 233.685 13.7547 233.246 13.96C232.807 14.1653 232.303 14.268 231.734 14.268L232 13.694C232.616 13.694 233.162 13.7967 233.638 14.002C234.114 14.198 234.483 14.492 234.744 14.884C235.015 15.276 235.15 15.7567 235.15 16.326C235.15 17.166 234.819 17.824 234.156 18.3C233.493 18.7667 232.518 19 231.23 19H226.162ZM228.416 17.292H231.062C231.65 17.292 232.093 17.1987 232.392 17.012C232.7 16.816 232.854 16.508 232.854 16.088C232.854 15.6773 232.7 15.374 232.392 15.178C232.093 14.9727 231.65 14.87 231.062 14.87H228.248V13.218H230.67C231.221 13.218 231.641 13.1247 231.93 12.938C232.229 12.742 232.378 12.448 232.378 12.056C232.378 11.6733 232.229 11.3887 231.93 11.202C231.641 11.006 231.221 10.908 230.67 10.908H228.416V17.292Z", fill: "white" }),
            React.createElement("g", { filter: "url(#filter0_d_72_2749)" },
                React.createElement("path", { d: "M219 42.5C142.6 96.1 54.5 100.833 20 96.5C122 112.5 199.5 72.1667 225.5 50L230.5 55.5L236 35H214L219 42.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_72_2749", x: "16", y: "35", width: "224", height: "73.1602", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_72_2749" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_72_2749", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_72_2749" },
                React.createElement("rect", { width: "265", height: "121", fill: "white", transform: "translate(0.5)" })))));
};
export const BtoA = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "266", height: "121", viewBox: "0 0 266 121", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_72_2787)" },
            React.createElement("path", { d: "M265.5 0H0.5V121H265.5V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "2", y1: "19.5", x2: "265", y2: "19.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11.5", y1: "6", x2: "11.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46.5", y1: "6", x2: "46.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81.5", y1: "6", x2: "81.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116.5", y1: "6", x2: "116.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151.5", y1: "6", x2: "151.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186.5", y1: "6", x2: "186.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221.5", y1: "6", x2: "221.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256.5", y1: "6", x2: "256.5", y2: "116", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "33.5", x2: "265", y2: "33.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "47.5", x2: "265", y2: "47.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "61.5", x2: "265", y2: "61.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "75.5", x2: "265", y2: "75.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "89.5", x2: "265", y2: "89.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "103.5", x2: "265", y2: "103.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M24.162 83V73.2H28.95C30.182 73.2 31.106 73.4333 31.722 73.9C32.3473 74.3667 32.66 74.9827 32.66 75.748C32.66 76.2613 32.534 76.7093 32.282 77.092C32.03 77.4653 31.6847 77.7547 31.246 77.96C30.8073 78.1653 30.3033 78.268 29.734 78.268L30 77.694C30.616 77.694 31.162 77.7967 31.638 78.002C32.114 78.198 32.4827 78.492 32.744 78.884C33.0147 79.276 33.15 79.7567 33.15 80.326C33.15 81.166 32.8187 81.824 32.156 82.3C31.4933 82.7667 30.518 83 29.23 83H24.162ZM26.416 81.292H29.062C29.65 81.292 30.0933 81.1987 30.392 81.012C30.7 80.816 30.854 80.508 30.854 80.088C30.854 79.6773 30.7 79.374 30.392 79.178C30.0933 78.9727 29.65 78.87 29.062 78.87H26.248V77.218H28.67C29.2207 77.218 29.6407 77.1247 29.93 76.938C30.2287 76.742 30.378 76.448 30.378 76.056C30.378 75.6733 30.2287 75.3887 29.93 75.202C29.6407 75.006 29.2207 74.908 28.67 74.908H26.416V81.292Z", fill: "white" }),
            React.createElement("path", { d: "M223.874 19L228.242 9.2H230.482L234.864 19H232.484L228.9 10.348H229.796L226.198 19H223.874ZM226.058 16.9L226.66 15.178H231.7L232.316 16.9H226.058Z", fill: "white" }),
            React.createElement("path", { d: "M211 25H244V103H211V25Z", fill: "#00B050" }),
            React.createElement("path", { d: "M244 25V103H251V26.6083L244 25Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M162 43H194V103H162V43Z", fill: "#00B050" }),
            React.createElement("path", { d: "M194 43V103H201V44.2371L194 43Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M113 57H145V103H113V57Z", fill: "#00B050" }),
            React.createElement("path", { d: "M145 57V103H153V57.9484L145 57Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M63 73H96V103H63V73Z", fill: "#00B050" }),
            React.createElement("path", { d: "M96 73V103H104V73.6185L96 73Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M15 86H47V103H15V86Z", fill: "#92D050" }),
            React.createElement("path", { d: "M47 86V103H55V86.3505L47 86Z", fill: "#639C26" }),
            React.createElement("g", { filter: "url(#filter0_d_72_2787)" },
                React.createElement("path", { d: "M219 42.5C142.6 96.1 54.5 100.833 20 96.5C122 112.5 199.5 72.1667 225.5 50L230.5 55.5L236 35H214L219 42.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_72_2787", x: "16", y: "35", width: "224", height: "73.1602", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_72_2787" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_72_2787", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_72_2787" },
                React.createElement("rect", { width: "265", height: "121", fill: "white", transform: "translate(0.5)" })))));
};
export const AtoA = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1415_3592)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "2", y1: "22.5", x2: "265", y2: "22.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11.5", y1: "9", x2: "11.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46.5", y1: "9", x2: "46.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81.5", y1: "9", x2: "81.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116.5", y1: "9", x2: "116.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151.5", y1: "9", x2: "151.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186.5", y1: "9", x2: "186.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221.5", y1: "9", x2: "221.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256.5", y1: "9", x2: "256.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "36.5", x2: "265", y2: "36.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "50.5", x2: "265", y2: "50.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "64.5", x2: "265", y2: "64.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "78.5", x2: "265", y2: "78.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "92.5", x2: "265", y2: "92.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "106.5", x2: "265", y2: "106.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M230.874 34L235.242 24.2H237.482L241.864 34H239.484L235.9 25.348H236.796L233.198 34H230.874ZM233.058 31.9L233.66 30.178H238.7L239.316 31.9H233.058Z", fill: "white" }),
            React.createElement("path", { d: "M25.874 34L30.242 24.2H32.482L36.864 34H34.484L30.9 25.348H31.796L28.198 34H25.874ZM28.058 31.9L28.66 30.178H33.7L34.316 31.9H28.058Z", fill: "white" }),
            React.createElement("path", { d: "M14 47H46.593V107H14V47Z", fill: "#00B050" }),
            React.createElement("path", { d: "M46.5938 47V107H54.0007V48.2371L46.5938 47Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M63 47H95.593V107H63V47Z", fill: "#00B050" }),
            React.createElement("path", { d: "M95.5938 47V107H103.001V48.2371L95.5938 47Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M112 47H144.593V107H112V47Z", fill: "#00B050" }),
            React.createElement("path", { d: "M144.594 47V107H152.001V48.2371L144.594 47Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M161 47H193.593V107H161V47Z", fill: "#00B050" }),
            React.createElement("path", { d: "M193.594 47V107H201.001V48.2371L193.594 47Z", fill: "#0C8844" }),
            React.createElement("path", { d: "M210 47H242.593V107H210V47Z", fill: "#00B050" }),
            React.createElement("path", { d: "M242.594 47V107H250.001V48.2371L242.594 47Z", fill: "#0C8844" }),
            React.createElement("g", { filter: "url(#filter0_d_1415_3592)" },
                React.createElement("path", { d: "M221.5 70.5L29 77L221.5 79V85L235 75L221.5 65V70.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1415_3592", x: "25", y: "65", width: "214", height: "28", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1415_3592" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1415_3592", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1415_3592" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
export const BtoB = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1415_3628)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "2", y1: "22.5", x2: "265", y2: "22.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11.5", y1: "9", x2: "11.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46.5", y1: "9", x2: "46.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81.5", y1: "9", x2: "81.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116.5", y1: "9", x2: "116.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151.5", y1: "9", x2: "151.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186.5", y1: "9", x2: "186.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221.5", y1: "9", x2: "221.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256.5", y1: "9", x2: "256.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "36.5", x2: "265", y2: "36.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "50.5", x2: "265", y2: "50.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "64.5", x2: "265", y2: "64.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "78.5", x2: "265", y2: "78.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "92.5", x2: "265", y2: "92.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "106.5", x2: "265", y2: "106.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M232.162 34V24.2H236.95C238.182 24.2 239.106 24.4333 239.722 24.9C240.347 25.3667 240.66 25.9827 240.66 26.748C240.66 27.2613 240.534 27.7093 240.282 28.092C240.03 28.4653 239.685 28.7547 239.246 28.96C238.807 29.1653 238.303 29.268 237.734 29.268L238 28.694C238.616 28.694 239.162 28.7967 239.638 29.002C240.114 29.198 240.483 29.492 240.744 29.884C241.015 30.276 241.15 30.7567 241.15 31.326C241.15 32.166 240.819 32.824 240.156 33.3C239.493 33.7667 238.518 34 237.23 34H232.162ZM234.416 32.292H237.062C237.65 32.292 238.093 32.1987 238.392 32.012C238.7 31.816 238.854 31.508 238.854 31.088C238.854 30.6773 238.7 30.374 238.392 30.178C238.093 29.9727 237.65 29.87 237.062 29.87H234.248V28.218H236.67C237.221 28.218 237.641 28.1247 237.93 27.938C238.229 27.742 238.378 27.448 238.378 27.056C238.378 26.6733 238.229 26.3887 237.93 26.202C237.641 26.006 237.221 25.908 236.67 25.908H234.416V32.292Z", fill: "white" }),
            React.createElement("path", { d: "M27.162 34V24.2H31.95C33.182 24.2 34.106 24.4333 34.722 24.9C35.3473 25.3667 35.66 25.9827 35.66 26.748C35.66 27.2613 35.534 27.7093 35.282 28.092C35.03 28.4653 34.6847 28.7547 34.246 28.96C33.8073 29.1653 33.3033 29.268 32.734 29.268L33 28.694C33.616 28.694 34.162 28.7967 34.638 29.002C35.114 29.198 35.4827 29.492 35.744 29.884C36.0147 30.276 36.15 30.7567 36.15 31.326C36.15 32.166 35.8187 32.824 35.156 33.3C34.4933 33.7667 33.518 34 32.23 34H27.162ZM29.416 32.292H32.062C32.65 32.292 33.0933 32.1987 33.392 32.012C33.7 31.816 33.854 31.508 33.854 31.088C33.854 30.6773 33.7 30.374 33.392 30.178C33.0933 29.9727 32.65 29.87 32.062 29.87H29.248V28.218H31.67C32.2207 28.218 32.6407 28.1247 32.93 27.938C33.2287 27.742 33.378 27.448 33.378 27.056C33.378 26.6733 33.2287 26.3887 32.93 26.202C32.6407 26.006 32.2207 25.908 31.67 25.908H29.416V32.292Z", fill: "white" }),
            React.createElement("path", { d: "M14 47H46.593V107H14V47Z", fill: "#92D050" }),
            React.createElement("path", { d: "M46.5938 47V107H54.0007V48.2371L46.5938 47Z", fill: "#639C26" }),
            React.createElement("path", { d: "M63 47H95.593V107H63V47Z", fill: "#92D050" }),
            React.createElement("path", { d: "M95.5938 47V107H103.001V48.2371L95.5938 47Z", fill: "#639C26" }),
            React.createElement("path", { d: "M112 47H144.593V107H112V47Z", fill: "#92D050" }),
            React.createElement("path", { d: "M144.594 47V107H152.001V48.2371L144.594 47Z", fill: "#639C26" }),
            React.createElement("path", { d: "M161 47H193.593V107H161V47Z", fill: "#92D050" }),
            React.createElement("path", { d: "M193.594 47V107H201.001V48.2371L193.594 47Z", fill: "#639C26" }),
            React.createElement("path", { d: "M210 47H242.593V107H210V47Z", fill: "#92D050" }),
            React.createElement("path", { d: "M242.594 47V107H250.001V48.2371L242.594 47Z", fill: "#639C26" }),
            React.createElement("g", { filter: "url(#filter0_d_1415_3628)" },
                React.createElement("path", { d: "M221.5 70.5L29 77L221.5 79V85L235 75L221.5 65V70.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1415_3628", x: "25", y: "65", width: "214", height: "28", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1415_3628" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1415_3628", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1415_3628" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
export const CtoC = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1415_3556)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "2", y1: "22.5", x2: "265", y2: "22.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11.5", y1: "9", x2: "11.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46.5", y1: "9", x2: "46.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81.5", y1: "9", x2: "81.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116.5", y1: "9", x2: "116.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151.5", y1: "9", x2: "151.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186.5", y1: "9", x2: "186.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221.5", y1: "9", x2: "221.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256.5", y1: "9", x2: "256.5", y2: "119", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "36.5", x2: "265", y2: "36.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "50.5", x2: "265", y2: "50.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "64.5", x2: "265", y2: "64.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "78.5", x2: "265", y2: "78.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "92.5", x2: "265", y2: "92.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "106.5", x2: "265", y2: "106.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M236.852 34.168C236.096 34.168 235.391 34.0467 234.738 33.804C234.094 33.552 233.534 33.1973 233.058 32.74C232.582 32.2827 232.209 31.746 231.938 31.13C231.677 30.514 231.546 29.8373 231.546 29.1C231.546 28.3627 231.677 27.686 231.938 27.07C232.209 26.454 232.582 25.9173 233.058 25.46C233.543 25.0027 234.108 24.6527 234.752 24.41C235.396 24.158 236.101 24.032 236.866 24.032C237.715 24.032 238.481 24.1813 239.162 24.48C239.853 24.7693 240.431 25.1987 240.898 25.768L239.442 27.112C239.106 26.7293 238.733 26.4447 238.322 26.258C237.911 26.062 237.463 25.964 236.978 25.964C236.521 25.964 236.101 26.0387 235.718 26.188C235.335 26.3373 235.004 26.552 234.724 26.832C234.444 27.112 234.225 27.4433 234.066 27.826C233.917 28.2087 233.842 28.6333 233.842 29.1C233.842 29.5667 233.917 29.9913 234.066 30.374C234.225 30.7567 234.444 31.088 234.724 31.368C235.004 31.648 235.335 31.8627 235.718 32.012C236.101 32.1613 236.521 32.236 236.978 32.236C237.463 32.236 237.911 32.1427 238.322 31.956C238.733 31.76 239.106 31.466 239.442 31.074L240.898 32.418C240.431 32.9873 239.853 33.4213 239.162 33.72C238.481 34.0187 237.711 34.168 236.852 34.168Z", fill: "white" }),
            React.createElement("path", { d: "M31.852 34.168C31.096 34.168 30.3913 34.0467 29.738 33.804C29.094 33.552 28.534 33.1973 28.058 32.74C27.582 32.2827 27.2087 31.746 26.938 31.13C26.6767 30.514 26.546 29.8373 26.546 29.1C26.546 28.3627 26.6767 27.686 26.938 27.07C27.2087 26.454 27.582 25.9173 28.058 25.46C28.5433 25.0027 29.108 24.6527 29.752 24.41C30.396 24.158 31.1007 24.032 31.866 24.032C32.7153 24.032 33.4807 24.1813 34.162 24.48C34.8527 24.7693 35.4313 25.1987 35.898 25.768L34.442 27.112C34.106 26.7293 33.7327 26.4447 33.322 26.258C32.9113 26.062 32.4633 25.964 31.978 25.964C31.5207 25.964 31.1007 26.0387 30.718 26.188C30.3353 26.3373 30.004 26.552 29.724 26.832C29.444 27.112 29.2247 27.4433 29.066 27.826C28.9167 28.2087 28.842 28.6333 28.842 29.1C28.842 29.5667 28.9167 29.9913 29.066 30.374C29.2247 30.7567 29.444 31.088 29.724 31.368C30.004 31.648 30.3353 31.8627 30.718 32.012C31.1007 32.1613 31.5207 32.236 31.978 32.236C32.4633 32.236 32.9113 32.1427 33.322 31.956C33.7327 31.76 34.106 31.466 34.442 31.074L35.898 32.418C35.4313 32.9873 34.8527 33.4213 34.162 33.72C33.4807 34.0187 32.7107 34.168 31.852 34.168Z", fill: "white" }),
            React.createElement("path", { d: "M14 47H46.593V107H14V47Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M46.5938 47V107H54.0007V48.2371L46.5938 47Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M63 47H95.593V107H63V47Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M95.5938 47V107H103.001V48.2371L95.5938 47Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M112 47H144.593V107H112V47Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M144.594 47V107H152.001V48.2371L144.594 47Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M161 47H193.593V107H161V47Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M193.594 47V107H201.001V48.2371L193.594 47Z", fill: "#B58E19" }),
            React.createElement("path", { d: "M210 47H242.593V107H210V47Z", fill: "#FFC000" }),
            React.createElement("path", { d: "M242.594 47V107H250.001V48.2371L242.594 47Z", fill: "#B58E19" }),
            React.createElement("g", { filter: "url(#filter0_d_1415_3556)" },
                React.createElement("path", { d: "M221.5 70.5L29 77L221.5 79V85L235 75L221.5 65V70.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1415_3556", x: "25", y: "65", width: "214", height: "28", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1415_3556" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1415_3556", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1415_3556" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
export const DtoD = () => {
    const renderKey = Math.random().toFixed(4);
    return (React.createElement("svg", { width: "265", height: "120", viewBox: "0 0 265 120", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
        React.createElement("g", { "clip-path": "url(#clip0_1336_1648)" },
            React.createElement("path", { d: "M265 0H0V120H265V0Z", fill: "#2B3B4A" }),
            React.createElement("line", { x1: "2", y1: "23.5", x2: "265", y2: "23.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "11.5", y1: "10", x2: "11.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "46.5", y1: "10", x2: "46.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "81.5", y1: "10", x2: "81.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "116.5", y1: "10", x2: "116.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "151.5", y1: "10", x2: "151.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "186.5", y1: "10", x2: "186.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "221.5", y1: "10", x2: "221.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "256.5", y1: "10", x2: "256.5", y2: "120", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "37.5", x2: "265", y2: "37.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "51.5", x2: "265", y2: "51.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "65.5", x2: "265", y2: "65.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "79.5", x2: "265", y2: "79.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "93.5", x2: "265", y2: "93.5", stroke: "#3B4A58" }),
            React.createElement("line", { x1: "2", y1: "107.5", x2: "265", y2: "107.5", stroke: "#3B4A58" }),
            React.createElement("path", { d: "M232.162 35V25.2H236.614C237.678 25.2 238.616 25.4053 239.428 25.816C240.24 26.2173 240.875 26.782 241.332 27.51C241.789 28.238 242.018 29.1013 242.018 30.1C242.018 31.0893 241.789 31.9527 241.332 32.69C240.875 33.418 240.24 33.9873 239.428 34.398C238.616 34.7993 237.678 35 236.614 35H232.162ZM234.43 33.138H236.502C237.155 33.138 237.72 33.0167 238.196 32.774C238.681 32.522 239.055 32.1673 239.316 31.71C239.587 31.2527 239.722 30.716 239.722 30.1C239.722 29.4747 239.587 28.938 239.316 28.49C239.055 28.0327 238.681 27.6827 238.196 27.44C237.72 27.188 237.155 27.062 236.502 27.062H234.43V33.138Z", fill: "white" }),
            React.createElement("path", { d: "M27.162 35V25.2H31.614C32.678 25.2 33.616 25.4053 34.428 25.816C35.24 26.2173 35.8747 26.782 36.332 27.51C36.7893 28.238 37.018 29.1013 37.018 30.1C37.018 31.0893 36.7893 31.9527 36.332 32.69C35.8747 33.418 35.24 33.9873 34.428 34.398C33.616 34.7993 32.678 35 31.614 35H27.162ZM29.43 33.138H31.502C32.1553 33.138 32.72 33.0167 33.196 32.774C33.6813 32.522 34.0547 32.1673 34.316 31.71C34.5867 31.2527 34.722 30.716 34.722 30.1C34.722 29.4747 34.5867 28.938 34.316 28.49C34.0547 28.0327 33.6813 27.6827 33.196 27.44C32.72 27.188 32.1553 27.062 31.502 27.062H29.43V33.138Z", fill: "white" }),
            React.createElement("path", { d: "M14 47H46.593V107H14V47Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M46.5938 47V107H54.0007V48.2371L46.5938 47Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M63 47H95.593V107H63V47Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M95.5938 47V107H103.001V48.2371L95.5938 47Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M112 47H144.593V107H112V47Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M144.594 47V107H152.001V48.2371L144.594 47Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M161 47H193.593V107H161V47Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M193.594 47V107H201.001V48.2371L193.594 47Z", fill: "#A31C1C" }),
            React.createElement("path", { d: "M210 47H242.593V107H210V47Z", fill: "#FF0000" }),
            React.createElement("path", { d: "M242.594 47V107H250.001V48.2371L242.594 47Z", fill: "#A31C1C" }),
            React.createElement("g", { filter: "url(#filter0_d_1336_1648)" },
                React.createElement("path", { d: "M221.5 70.5L29 77L221.5 79V85L235 75L221.5 65V70.5Z", fill: "white" }))),
        React.createElement("defs", null,
            React.createElement("filter", { id: "filter0_d_1336_1648", x: "25", y: "65", width: "214", height: "28", filterUnits: "userSpaceOnUse", "color-interpolation-filters": "sRGB" },
                React.createElement("feFlood", { "flood-opacity": "0", result: "BackgroundImageFix" }),
                React.createElement("feColorMatrix", { in: "SourceAlpha", type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0", result: "hardAlpha" }),
                React.createElement("feOffset", { dy: "4" }),
                React.createElement("feGaussianBlur", { stdDeviation: "2" }),
                React.createElement("feComposite", { in2: "hardAlpha", operator: "out" }),
                React.createElement("feColorMatrix", { type: "matrix", values: "0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" }),
                React.createElement("feBlend", { mode: "normal", in2: "BackgroundImageFix", result: "effect1_dropShadow_1336_1648" }),
                React.createElement("feBlend", { mode: "normal", in: "SourceGraphic", in2: "effect1_dropShadow_1336_1648", result: "shape" })),
            React.createElement("clipPath", { id: "clip0_1336_1648" },
                React.createElement("rect", { width: "265", height: "120", fill: "white" })))));
};
