import React from "react";
import localize from "../../../../../core/utils/translation-files";
import Asterik from "../../../shared/helper-components/Asterik";
import ConditionalViewFormField from "../../../../../layout/components/conditionalViewFormField";
import { Input, Switch } from "antd";
const XeroFormFields = ({ values, errors, touched, handleChange, isReadOnly, setFieldValue }) => {
    return (React.createElement("div", { className: "box-main-section" },
        React.createElement("section", { className: "box-section" },
            React.createElement("div", { className: "box-title" },
                React.createElement("div", { className: "box-heading" }, localize(["Site.xero", "Site.financialSystem"]))),
            React.createElement("div", { className: "box-description" },
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.tenantId"]),
                                    " ",
                                    React.createElement(Asterik, { hidden: isReadOnly }))),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.tenantId },
                                React.createElement(Input, { name: "siteDetail.tenantId", placeholder: localize(["Site.tenantId"]), value: values.siteDetail.tenantId, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.tenantId &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.tenantId
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.tenantId &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.tenantId && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.tenantId))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.trackingCategoryId"]),
                                    " ")),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.trackingCategoryId },
                                React.createElement(Input, { name: "siteDetail.trackingCategoryId", placeholder: localize(["Site.trackingCategoryId"]), value: values.siteDetail.trackingCategoryId, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.trackingCategoryId &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.trackingCategoryId
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.trackingCategoryId &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.trackingCategoryId && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.trackingCategoryId))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.trackingOptionId"]),
                                    " ")),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.trackingOptionId },
                                React.createElement(Input, { name: "siteDetail.trackingOptionId", placeholder: localize(["Site.trackingOptionId"]), value: values.siteDetail.trackingOptionId, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.trackingOptionId &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.trackingOptionId
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.trackingOptionId &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.trackingOptionId && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.trackingOptionId))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.budgetId"]),
                                    " ")),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.budgetId },
                                React.createElement(Input, { name: "siteDetail.budgetId", placeholder: localize(["Site.budgetId"]), value: values.siteDetail.budgetId, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.budgetId &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.budgetId
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.budgetId &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.budgetId && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.budgetId))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.accessToken"]),
                                    " ",
                                    React.createElement(Asterik, { hidden: isReadOnly }))),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.accessToken },
                                React.createElement(Input, { name: "siteDetail.accessToken", placeholder: localize(["Site.accessToken"]), value: values.siteDetail.accessToken, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.accessToken &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.accessToken
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.accessToken &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.accessToken && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.accessToken))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.refreshToken"]),
                                    " ",
                                    React.createElement(Asterik, { hidden: isReadOnly }))),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.refreshToken },
                                React.createElement(Input, { name: "siteDetail.refreshToken", placeholder: localize(["Site.refreshToken"]), value: values.siteDetail.refreshToken, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.refreshToken &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.refreshToken
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.refreshToken &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.refreshToken && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.refreshToken))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.clientId"]),
                                    " ",
                                    React.createElement(Asterik, { hidden: isReadOnly }))),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.clientId },
                                React.createElement(Input, { name: "siteDetail.clientId", placeholder: localize(["Site.clientId"]), value: values.siteDetail.clientId, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.clientId &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.clientId
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.clientId &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.clientId && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.clientId))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col bold-text" },
                                React.createElement("label", null,
                                    localize(["Site.clientSecret"]),
                                    " ",
                                    React.createElement(Asterik, { hidden: isReadOnly }))),
                            React.createElement(ConditionalViewFormField, { isViewMode: isReadOnly, labelText: values.siteDetail.clientSecret },
                                React.createElement(Input, { name: "siteDetail.clientSecret", placeholder: localize(["Site.clientSecret"]), value: values.siteDetail.clientSecret, onChange: handleChange, className: `${errors &&
                                        errors.siteDetail &&
                                        errors.siteDetail.clientSecret &&
                                        touched &&
                                        touched.siteDetail &&
                                        touched.siteDetail.clientSecret
                                        ? "input-error"
                                        : null}` }),
                                errors &&
                                    errors.siteDetail &&
                                    errors.siteDetail.clientSecret &&
                                    touched &&
                                    touched.siteDetail &&
                                    touched.siteDetail.clientSecret && (React.createElement("p", { className: "error" }, errors && errors.siteDetail.clientSecret))))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", "data-test": "group-company-holding-accounts" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label mr-3 mt-1" },
                                React.createElement("label", null, localize(["Company.xeroFinancialAccess"]))),
                            React.createElement(Switch, { className: "ml-1 mb-1", checked: values.siteDetail.xeroFinancialAccess, onChange: (checked, event) => setFieldValue("siteDetail.xeroFinancialAccess", !values.siteDetail.xeroFinancialAccess) }))),
                    React.createElement("div", { className: "col-lg-3 col-md-4 col-sm-6", "data-test": "group-company-holding-accounts" },
                        React.createElement("div", { className: "form-group" },
                            React.createElement("div", { className: "ant-col ant-form-item-label mr-3 mt-1" },
                                React.createElement("label", null, localize(["Company.xeroBudgetAccess"]))),
                            React.createElement(Switch, { className: "ml-1 mb-1", checked: values.siteDetail.xeroBudgetAccess, onChange: (checked, event) => setFieldValue("siteDetail.xeroBudgetAccess", !values.siteDetail.xeroBudgetAccess) }))))))));
};
export default XeroFormFields;
