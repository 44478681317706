import { Tooltip } from "antd";
import React from "react";
import isEmpty from "../../../../../../../core/helpers/isEmpty";
import RatingAccuracy from "../../../../../shared/dashboard/components/ratingAccuracy";
const WatchlistTitleContainer = ({ row, data }) => {
    return (React.createElement("div", { className: "watchlistTitleContainer ratingAccuracy", style: { alignItems: "flex-end", paddingRight: "10px" } },
        React.createElement(Tooltip, { title: React.createElement("span", { style: { display: "block", whiteSpace: "normal", maxWidth: "90vw" } }, data === null || data === void 0 ? void 0 : data.itemName), placement: "topLeft", overlayStyle: { whiteSpace: "normal" }, overlayClassName: "watchlistHierarchyTooltip" },
            React.createElement("h6", { className: "bold-text-color" }, data === null || data === void 0 ? void 0 : data.itemName)),
        !isEmpty(data === null || data === void 0 ? void 0 : data.ratingAccuracy) && React.createElement(RatingAccuracy, { data: data })));
};
export default WatchlistTitleContainer;
