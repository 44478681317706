import moment from "moment";
import { CompanyRequest, CompanyResponse } from "./../company/companyContracts";
export class SiteDetail {
}
export class SiteRequest extends CompanyRequest {
}
export class SiteResponse extends CompanyResponse {
}
export let defaultSiteDetail = {
    cqcRatingDate: moment(new Date()).format("YYYY-MM-DD"),
    registeredActivities: "",
    website: "",
    safe: "",
    effective: "",
    caring: "",
    responsive: "",
    wellLed: "",
    financialMonth: "",
    noOfRegisteredBeds: null,
    financialSystem: 0,
    ingestionYear: "",
    tenantId: "",
    trackingCategoryId: "",
    trackingOptionId: "",
    budgetId: "",
    accessToken: "",
    refreshToken: "",
    clientId: "",
    clientSecret: "",
    financialSystemText: "",
    weight: 0,
    xeroFinancialAccess: false,
    xeroBudgetAccess: false
};
