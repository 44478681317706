import React, { useContext, useEffect, useMemo, useState } from "react";
import ChartActionButtons from "./components/chartActionButtons";
import MovingAverageAndProjectionChart from "./components/movingAverageAndProjectionChart";
import { useHistory, useParams } from "react-router-dom";
import TrackedItemBandScaleContainer from "./trackedItemBandScale/trackedItemBandScaleContainer";
import UsePrintPage from "../../../../../../core/hooks/usePrintPage";
import ChartApi from "../../../../../../core/api/chartApi";
import { useSelector, connect } from "react-redux";
import useDidMount from "../../../../../../core/hooks/useDidMount";
import { getLookups } from "../../../../../../redux/lookup/actions";
import { LookupNames } from "../../../../../../core/constants/lookup";
import { getLookupByListing } from "../../../../../../core/helpers/lookupHanlder";
import { reportsRoute, unAuthorizedRoute, } from "../../../../../../core/constants/routesPath";
import { getBreadCrumbSession, handleSetRoadMapItemWatchListStatus, } from "../../../../../../core/helpers/localStorage/breadCrumbSession";
import { ChartFilterOptions } from "../../../../../../core/enums/chart";
import { exportActualDataForChart, exportChartData, exportFinancialDataSheet, } from "../../../../../../core/helpers/chartYAxisLabel";
import BreadcrumbNavigation from "../../../../../../layout/components/breadCrumbNavigation";
import CustomModal from "../../../../../../layout/components/customModal";
import { getUserCreatedTrackedItems } from "../../../../../../redux/trackedItem/actions";
import AssociatedTrackItemListing from "./components/associatedTrackItemListing";
import ChartPopupContainer from "./components/chartPopupContainer";
import isEmpty from "../../../../../../core/helpers/isEmpty";
import { browserHistoryMemoryContext } from "../../../../../../core/contexts/browserHistoryMemoryContext";
import isActionAllowed from "../../../../../../core/authorization/userPermissions";
import { USER_REPORTS, USER_REPORTS_FINANCIAL, } from "../../../../../../core/constants/permissionConstants";
import { SessionStorage } from "../../../../../../core/helpers/localStorage/sessionStorage";
import { SessionVariables } from "../../../../../../core/constants/SessionVariables";
import ChartVariablesModal from "./chartVariables/chartVariablesModal";
import "./components/movingAverage.scss";
import OverallStatusPanels from "../../../dashboard/components/OverallStatusPanels";
import RelatedNotificationsPopup from "../../../dashboard/components/relatedNotificationsPopup";
import localize from "../../../../../../core/utils/translation-files";
import Header from "../../../../../../layout/components/common/header";
import PageDescription from "../../../../../../layout/components/common/pageDescription";
import RenderSvg from "../../../../../../layout/components/icons/RenderSvg";
import AddRemoveWatchListModal from "../../../dashboard/DashboardRoadmap/components/addRemoveWatchList";
import { FileTextOutlined } from "@ant-design/icons";
import DataSheetsModal from "../../../reports/components/DataSheetsModal";
import { Button } from "antd";
import { TrackedItemApi } from "../../../../../../core/api/trackedItemApi";
import { ReportsVariables } from "../../../../../../core/constants/ReportConstants";
const MovingAverageAndProjectionChartContainer = ({ getLookups, getUserCreatedTrackedItems, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const isReportViewAllowed = isActionAllowed([USER_REPORTS]);
    const isFinancialDataViewAllowed = isActionAllowed([USER_REPORTS_FINANCIAL]);
    const params = useParams();
    const [chartData, setChartData] = useState({
        totalPages: 1,
        currentPage: 0,
        data: [],
        loadedProjectedData: false,
    });
    const handleResetChartData = () => {
        setChartData({
            totalPages: 1,
            currentPage: 0,
            data: [],
            loadedProjectedData: false,
        });
    };
    const hist = useHistory();
    const { browserHistory } = useContext(browserHistoryMemoryContext);
    const history = useMemo(() => {
        return browserHistory !== null && browserHistory !== void 0 ? browserHistory : hist;
    }, [browserHistory, hist]);
    const [overallData, setOverallData] = useState(undefined);
    const stateParams = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.params;
    const parameters = stateParams && new URLSearchParams(stateParams);
    const isNotiRedirect = parameters && (!!parameters.get("isNotification") || false);
    const [selectedFilterValue, setSelectedFilterValue] = useState(ChartFilterOptions.Default);
    const [filters, setFilters] = useState();
    useEffect(() => {
        var _a, _b, _c;
        setFilters(((_b = (_a = history.location.state) === null || _a === void 0 ? void 0 : _a.filters) === null || _b === void 0 ? void 0 : _b.siteId)
            ? (_c = history.location.state) === null || _c === void 0 ? void 0 : _c.filters
            : {
                groupId: overallData === null || overallData === void 0 ? void 0 : overallData.parentGroupId,
                companyId: overallData === null || overallData === void 0 ? void 0 : overallData.parentCompanyId,
                siteId: overallData === null || overallData === void 0 ? void 0 : overallData.trackedItemCompanyId,
            });
    }, [overallData, (_b = history.location.state) === null || _b === void 0 ? void 0 : _b.filters]);
    // const [filters] = useState(() => {
    //   const mapObject = { 0: "groupId", 1: "companyId", 2: "siteId" };
    //   const startIndex =
    //     getBreadCrumbSession() &&
    //     getBreadCrumbSession()[0]?.path?.endsWith("dashboard")
    //       ? 1
    //       : 0;
    //   const filterList = getBreadCrumbSession()
    //     ?.slice(startIndex, startIndex + 3)
    //     ?.map(({ path }) => path?.split("/").at(-1));
    //   const object = filterList?.reduce(
    //     (prev, curr, index) => ({ ...prev, [mapObject[index]]: +curr }),
    //     {}
    //   );
    //   return history.location.state?.filters ?? object;
    // });
    const [isSummary] = useState((_c = history.location.state) === null || _c === void 0 ? void 0 : _c.isSummary);
    // const [trackedItemId] = useState(() => {
    //   //breadcrum with memory router /route state cleared
    //   const trackItemId = getBreadCrumbSession()
    //     ?.slice(-1)[0]
    //     ?.path?.substring(1);
    //   return getBreadCrumbSession()?.length > 1
    //     ? trackItemId
    //     : history.location.state?.trackedItemId ??
    //         history.location.pathname.split("/")[3];
    // });
    const [trackedItemId] = useState(() => {
        var _a, _b, _c;
        const breadcrumbSessions = getBreadCrumbSession();
        if ((breadcrumbSessions === null || breadcrumbSessions === void 0 ? void 0 : breadcrumbSessions.length) > 1) {
            return (_a = breadcrumbSessions[(breadcrumbSessions === null || breadcrumbSessions === void 0 ? void 0 : breadcrumbSessions.length) - 1]) === null || _a === void 0 ? void 0 : _a.key;
        }
        else if ((_b = history.location.state) === null || _b === void 0 ? void 0 : _b.trackedItemId) {
            return (_c = history.location.state) === null || _c === void 0 ? void 0 : _c.trackedItemId;
        }
        else {
            const paths = history.location.pathname.split("/");
            return paths[paths.length - 1];
        }
    });
    const [selectedRange, setSelectedRange] = useState([]);
    const [chartVariableModalVisible, setChartVariableModal] = useState(false);
    const [scaleModelVisibility, setScaleModelVisibility] = useState(false);
    // const [exportItemsModelVisibility, setExportItemsModelVisibility] =
    //   useState(false);
    // const [exportIndividualModelVisibility, setExportIndividualModelVisibility] =
    //   useState(false);
    const [dataSheetModalVisibility, setDataSheetModalVisibility] = useState(false);
    const [trackedItemInfo, setTrackedItemInfo] = useState(undefined);
    const { handlePrint, nodeRef } = UsePrintPage();
    const hide = useSelector((state) => state.togglePrint);
    const trackedItemValueTypeLookups = useSelector((state) => { var _a; return (_a = state.lookups[LookupNames.LKReportValueType]) !== null && _a !== void 0 ? _a : []; });
    const [chartPopupVisible, setChartPopupVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [notificationModelVisibility, setNotificationModelVisibility] = useState(false);
    const [watchlistModalVisible, setWatchListModalVisible] = useState(false);
    const handleToggleWatchListModal = (event) => {
        setWatchListModalVisible((prev) => !prev);
    };
    const openNotificationModal = (item) => {
        setNotificationModelVisibility(true);
        setSelectedItem(item);
    };
    const closeNotificationModal = () => {
        setNotificationModelVisibility(false);
        setSelectedItem({});
    };
    const [excelData, setExcelData] = useState();
    const [formula, setFormula] = useState();
    const completedResult = (dataObject, formula) => {
        setExcelData(dataObject);
        formula &&
            setFormula(formula === null || formula === void 0 ? void 0 : formula.substring((formula === null || formula === void 0 ? void 0 : formula.indexOf("=")) + 1, formula === null || formula === void 0 ? void 0 : formula.length));
    };
    const openDataSheetModal = () => {
        setExcelData(undefined);
        setDataSheetModalVisibility(true);
        exportFinancialDataSheet(selectedFilterValue, trackedItemId, selectedRange, chartVariables, completedResult, true);
    };
    const closeDataSheetModal = () => {
        setDataSheetModalVisibility(false);
    };
    const [chartVariables, setChartVariables] = useState(undefined);
    const [bandScales, setBandScales] = useState(undefined);
    const [selectedAssociatedId, setAssociatedId] = useState(undefined);
    useDidMount(() => {
        getLookupByListing({
            LookupNames: [
                LookupNames.LKReportValueType,
                LookupNames.LKChartDisplayUnit,
                LookupNames.LKChartDataFilter,
            ],
            getLookups,
        });
        if (trackedItemInfo === undefined && trackedItemId) {
            ChartApi.getChartVariables(params.id && params.id).then((res) => {
                setTrackedItemInfo(res);
            });
        }
        if (overallData === undefined && trackedItemId) {
            ChartApi.getChartTopCardsData(params.id && params.id).then((res) => {
                setOverallData(res);
            });
        }
        TrackedItemApi.getTrackedItemScaleById(params.id && params.id).then((res) => {
            setBandScales(res);
        });
    });
    const handleToggleChartModalVisibilty = () => {
        setChartVariableModal((prev) => !prev);
    };
    // const handleToggleExportModalVisibilty = () => {
    //   setExportItemsModelVisibility((prev) => !prev);
    // };
    // const handleToggleIndividualModalVisibilty = () => {
    //   setExportIndividualModelVisibility((prev) => !prev);
    // };
    const closeScaleModal = () => setScaleModelVisibility(false);
    const openScaleModal = () => {
        setScaleModelVisibility(true);
    };
    if (!trackedItemId &&
        ((_d = getBreadCrumbSession()) === null || _d === void 0 ? void 0 : _d.length) <= 1 &&
        !((_e = history.location.state) === null || _e === void 0 ? void 0 : _e.RedirectedPath)) {
        //default bread crumb for dashboard viewer 1
        //if more than one mean redirecting from TrackedItem dashboard having bread crumb > 1
        history.go(-1);
        // history.push(trackedItemsListingRoute);
    }
    const handleChartPopupToggle = (event) => {
        setAssociatedId(event.currentTarget.id);
        setChartPopupVisible((prev) => !prev);
    };
    const viewFinancialModule = () => {
        var _a;
        if (!isEmpty(filters === null || filters === void 0 ? void 0 : filters.siteId)) {
            SessionStorage.setKey(SessionVariables.FINANCIAL_MODULE, {
                filterValues: filters,
                tab: (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeFilter.toString()) ===
                    ReportsVariables.BUDGET_VS_ACTUAL_FILTER
                    ? ReportsVariables.BUDGET_VS_ACTUAL_TAB
                    : "4",
                tabFilter: (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeFilter.toString()) ||
                    ReportsVariables.FINANCIAL_FILTER,
                type: chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportType,
                parentKey: overallData.parentKey && ((_a = overallData.parentKey) !== null && _a !== void 0 ? _a : undefined),
            });
            isReportViewAllowed && isFinancialDataViewAllowed
                ? history.push(reportsRoute)
                : history.push(unAuthorizedRoute, {
                    subText: "permission",
                });
        }
    };
    const handleExportActualData = () => {
        if ((chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeId) && (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.companyId)) {
            TrackedItemApi.getAssociatedTrackItemByDefaults(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeId.toString(), chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.companyId).then((res) => {
                exportActualDataForChart(selectedFilterValue, trackedItemId, selectedRange, chartVariables, res);
            });
        }
    };
    // useEffect(() => {
    //   return () => {
    //     !history?.location?.pathname?.includes("dashboard") &&
    //       clearDashboardSession();
    //   };
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { ref: nodeRef },
            React.createElement(Header, { dataTest: "tracked-item-dashboard", leftComponent: React.createElement("span", { style: { display: "inline-flex", alignItems: "center" }, className: "dashboardTitleContainer " },
                    React.createElement(RenderSvg, { icon: "HomeAlt", style: {
                            marginLeft: "0px",
                            marginRight: "7px",
                            marginTop: "-2px",
                        }, 
                        // onClick={() => {
                        //   clearDashboardSession();
                        //   history.push(dashboardRoute);
                        // }}
                        hidden: 
                        // !history.location.pathname.includes("dashboard") ||
                        !((_f = getBreadCrumbSession()) === null || _f === void 0 ? void 0 : _f.length) }),
                    React.createElement("h5", null, history.location.pathname.includes("dashboard")
                        ? localize(["Dashboard.trackedItemdashboard"])
                        : "")), rightComponent: React.createElement(ChartActionButtons, { printHandle: handlePrint, openScaleModal: openScaleModal, chartVariables: chartVariables, setSelectedFilterValue: setSelectedFilterValue, selectedFilterValue: selectedFilterValue, setSelectedRange: setSelectedRange, selectedRange: selectedRange, browserHistory: history, setChartData: handleResetChartData, chartData: chartData, trackedItemId: params.id && params.id, exportHandle: exportChartData, exportFinancialSheet: exportFinancialDataSheet, handleExportActualData: handleExportActualData, isDashboard: ((_g = getBreadCrumbSession()) === null || _g === void 0 ? void 0 : _g.length) > 1 ||
                        // isSummary ||
                        history.location.pathname.includes("dashboard"), hideBackButton: 
                    // getBreadCrumbSession()?.length > 1 ||
                    !isSummary && history.location.pathname.includes("dashboard"), viewFinancialModule: viewFinancialModule, watchlistStatus: overallData === null || overallData === void 0 ? void 0 : overallData.watchlistStatus, isSummary: isSummary, isNotification: isNotiRedirect, handleToggleChartModalVisibilty: handleToggleChartModalVisibilty, 
                    // handleToggleExportModalVisibilty={
                    //   handleToggleExportModalVisibilty
                    // }
                    handleToggleWatchListModal: handleToggleWatchListModal }) }),
            React.createElement("div", { className: "mt-3", hidden: !history.location.pathname.includes("dashboard") ||
                    !((_h = getBreadCrumbSession()) === null || _h === void 0 ? void 0 : _h.length) },
                React.createElement(BreadcrumbNavigation, { isTrackedItem: true })),
            React.createElement(PageDescription, { description: overallData === null || overallData === void 0 ? void 0 : overallData.description, isLoading: !overallData }),
            React.createElement("div", { className: "mt-4 mb-2", style: {
                    width: "100%",
                    display: "inline-flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "space-between",
                } },
                React.createElement("h5", { style: { display: "inline-block" } }, overallData === null || overallData === void 0 ? void 0 : overallData.itemName),
                !isEmpty(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeFilter) &&
                    (chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.reportTypeFilter) !== -1 && (React.createElement(Button, { type: "primary", onClick: openDataSheetModal, icon: React.createElement(FileTextOutlined, { style: { fontSize: "16px" } }), 
                    // size="small"
                    style: {
                        // width: 90, height: 25, marginLeft: "10px"
                        marginRight: 0,
                    } }, "View Financial Data"))),
            React.createElement(OverallStatusPanels, { data: overallData !== null && overallData !== void 0 ? overallData : [], openNotificationModal: openNotificationModal, isChart: true, showAllCards: true, className: "movingAverageAndProjection", currencyFormat: !!(chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.currencyFormat) }),
            React.createElement(MovingAverageAndProjectionChart, { trackedItemId: params.id && params.id, chartVariables: chartVariables, setChartVariables: setChartVariables, isPrintDialogOpen: hide.status, trackedItemValueTypeLookups: trackedItemValueTypeLookups, selectedFilterValue: selectedFilterValue, selectedRange: selectedRange, chartData: chartData, setChartData: setChartData, trackedItemInfo: trackedItemInfo }),
            React.createElement(AssociatedTrackItemListing, { associatedList: chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.associationsList, handleClick: handleChartPopupToggle, selectedTrackItem: chartPopupVisible && selectedAssociatedId }),
            React.createElement(CustomModal, { visible: chartPopupVisible, onCancel: handleChartPopupToggle, maskClosable: false, destroyOnClose: true, footer: null, width: "95%" },
                React.createElement(ChartPopupContainer, { trackItemId: selectedAssociatedId, associatedList: chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.associationsList, selectedFilterValue: selectedFilterValue, selectedRange: selectedRange })),
            React.createElement(ChartVariablesModal, { visible: chartVariableModalVisible, onClose: handleToggleChartModalVisibilty, chartVariables: trackedItemInfo, hideDescription: ((_j = getBreadCrumbSession()) === null || _j === void 0 ? void 0 : _j.length) > 1, bandScales: bandScales })),
        bandScales && (React.createElement(TrackedItemBandScaleContainer, { visible: scaleModelVisibility, onClose: closeScaleModal, subCategory: trackedItemInfo === null || trackedItemInfo === void 0 ? void 0 : trackedItemInfo.itemCategoryId, trackedItemId: trackedItemId, dashboardBandScale: bandScales })),
        React.createElement(RelatedNotificationsPopup, { itemId: selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.itemId, visible: notificationModelVisibility, onClose: closeNotificationModal, selectedItem: selectedItem, setSelectedItem: setSelectedItem, browserHistory: history, setOverallData: setOverallData }),
        React.createElement(AddRemoveWatchListModal, { handleToggleModalVisibility: handleToggleWatchListModal, selectedTrackItemId: trackedItemId, visible: watchlistModalVisible, onAddRemoveWatchList: (itemId, status) => {
                setOverallData((prev) => (Object.assign(Object.assign({}, prev), { watchlistStatus: status })));
                handleSetRoadMapItemWatchListStatus(itemId, status);
            } }),
        React.createElement(DataSheetsModal, { onClose: closeDataSheetModal, visible: dataSheetModalVisibility, data: excelData, formula: formula, setFormula: setFormula, exportDataSheet: exportFinancialDataSheet, selectedFilters: {
                reportId: params.id,
                reportName: chartVariables === null || chartVariables === void 0 ? void 0 : chartVariables.name,
                startDate: selectedRange[0],
                endDate: selectedRange[1],
                reportFilterOption: selectedFilterValue,
            }, isChart: true })));
};
MovingAverageAndProjectionChartContainer.propTypes = {};
const mapDispatchToProps = {
    getLookups,
    getUserCreatedTrackedItems,
};
export default connect(null, mapDispatchToProps)(MovingAverageAndProjectionChartContainer);
