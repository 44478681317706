import React, { useState } from "react";
import SiteForm from "../components/siteForm";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { editSite, getSites } from "../../../../../redux/site/actions";
import { notifications } from "../../../../../layout/components/notifications";
import { useHistory, useParams } from "react-router-dom";
import { sitesRoute } from "../../../../../core/constants/routesPath";
import { ResponseStatusCodes } from "../../../../../core/constants/responseStatusCodes";
import localize from "../../../../../core/utils/translation-files";
import { getLookupByListing } from "../../../../../core/helpers/lookupHanlder";
import { getLookups } from "../../../../../redux/lookup/actions";
import { LookupNames } from "../../../../../core/constants/lookup";
import { AddressType } from "../../../../../core/enums/AddressTypes";
import { TelephoneType } from "../../../../../core/enums/TelephoneType";
import { CompanyType } from "../../../../../core/enums/CompanyType";
import { SiteApi } from "../../../../../core/api/siteApi";
import useDidMount from "../../../../../core/hooks/useDidMount";
const EditSite = ({ sites, getSites, editSite, context, companies, getLookups, }) => {
    var _a, _b, _c, _d;
    const initialSite = {
        id: 0,
        parentId: (_a = context === null || context === void 0 ? void 0 : context.selectedCompany) === null || _a === void 0 ? void 0 : _a.id,
        name: undefined,
        type: CompanyType.Site,
        registrationNumber: "",
        cqcRegistrationNumber: "",
        contactName: "",
        primaryTelephoneType: TelephoneType.Mobile,
        primaryTelephoneNumber: "",
        secondaryTelephoneType: TelephoneType.Mobile,
        secondaryTelephoneNumber: "",
        emailAddress: "",
        siteDetail: {
            cqcRatingDate: undefined,
            registeredActivities: "",
            website: "",
            safe: "",
            effective: "",
            caring: "",
            responsive: "",
            wellLed: "",
            financialMonth: "",
            noOfRegisteredBeds: undefined,
            financialSystem: 0,
            ingestionYear: "",
            tenantId: "",
            accessToken: "",
            refreshToken: "",
            clientId: "",
            clientSecret: "",
            financialSystemText: "",
            weight: 0,
            xeroFinancialAccess: false,
            xeroBudgetAccess: false,
        },
        addresses: [
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.RegisteredAddress,
                id: null,
            },
            {
                name: "",
                line1: "",
                line2: "",
                town: "",
                county: "",
                postCode: "",
                type: AddressType.ContactAddress,
                id: null,
            },
        ],
        active: true,
        holdingCompany: false,
        weight: 0,
        externalReference: "0000",
    };
    const history = useHistory();
    const params = useParams();
    const [selectedSite, setSelectedSite] = useState(initialSite);
    useDidMount(() => {
        var _a;
        if ((_a = history.location.state) === null || _a === void 0 ? void 0 : _a.siteId) {
            getLookupByListing({
                LookupNames: [
                    LookupNames.LKMonth,
                    LookupNames.LKRegisteredActivities,
                    LookupNames.LKFinancialSystem,
                    LookupNames.LKReportWeight,
                ],
                getLookups,
            });
            SiteApi.getSingleSite(parseInt(params === null || params === void 0 ? void 0 : params.id)).then((res) => {
                var _a;
                setSelectedSite(Object.assign(Object.assign({}, res), { externalReference: (_a = res === null || res === void 0 ? void 0 : res.externalReference) === null || _a === void 0 ? void 0 : _a.toString() }));
            });
        }
    });
    const editSiteHandler = (site, resetForm, setErrors) => {
        console.log({ site });
        editSite(site).then((response) => {
            if (response &&
                (response.code === ResponseStatusCodes.DATA_CONSTRAINT_VIOLATION ||
                    response.code === ResponseStatusCodes.VALIDATION_ERROR)) {
                setErrors({
                    [response.errorField]: response.message,
                });
            }
            else {
                resetForm();
                notifications.success(localize(["Site updated successfully"]));
                history.push(sitesRoute);
            }
        });
    };
    if (!((_b = history.location.state) === null || _b === void 0 ? void 0 : _b.siteId)) {
        history.push(sitesRoute);
    }
    return (React.createElement(React.Fragment, null, (selectedSite === null || selectedSite === void 0 ? void 0 : selectedSite.name) !== undefined && (React.createElement(SiteForm, { onSubmit: editSiteHandler, site: selectedSite, submitText: "Save", sites: sites, companies: companies, isEdit: true, isReadOnly: (_d = (_c = history.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.isReadOnly }))));
};
function mapStateTopProps(state) {
    return {
        sites: state.sites,
        context: state.context,
        companies: state.companies,
    };
}
const mapDispatchToProps = {
    editSite,
    getSites,
    getLookups,
};
EditSite.propTypes = {
    sites: PropTypes.array.isRequired,
    editSite: PropTypes.func.isRequired,
    getSites: PropTypes.func.isRequired,
};
export default connect(mapStateTopProps, mapDispatchToProps)(EditSite);
