export class ReportsVariables {
}
ReportsVariables.DEFAULT_TAB = "1";
ReportsVariables.BUDGET_DEFAULT_TAB = "5";
ReportsVariables.BUDGET_VS_ACTUAL_TAB = "9";
ReportsVariables.BUDGET_VS_ACTUAL_ID = 5;
ReportsVariables.FINANCIAL_FILTER = "0";
ReportsVariables.BUDGET_VS_ACTUAL_FILTER = "1";
ReportsVariables.BUDGET_VS_ACTUAL_TAB_INDEX = 4;
export const COLUMN_HEADERS = [
    {
        id: 7,
        headers: ["YTDBudget", "YTDActual", "AmountDeviation", "%Deviation"],
    },
    {
        id: 6,
        headers: ["MonthBudget", "MonthActual", "AmountDeviation", "%Deviation"],
    },
    {
        id: 8,
        headers: [
            "MonthActual",
            "SameMonthPreviousYearActual",
            "AmountDeviation",
            "%Deviation",
        ],
    },
    {
        id: 9,
        headers: [
            "YTDActual",
            "PreviousYTDActual",
            "AmountDeviation",
            "%Deviation",
        ],
    },
];
